import React from 'react';

import NavBar from '../components/NavBar';

const LoggedInWrapper = ({ children }: { children: JSX.Element }) => {
    return (
        <div className="min-h-screen bg-gray-100 pb-48 print:bg-white">
            <NavBar />
            {children}
        </div>
    )
}

export default LoggedInWrapper;