import React from 'react';
import { useClearCache } from '@herowcode/react-clear-cache';
import { RouterProvider } from 'react-router-dom';
import router from './Routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalProvider } from './context/globals';
import { UserProvider } from './context/User';
import { Toaster } from 'react-hot-toast';





function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache({ filename: 'meta.json' });
  if (!isLatestVersion) {
    setTimeout(() => {
      emptyCacheStorage();
    }, 2000);
    return (
      <>

        <h1 className={`mt-20 text-3xl text-center`}>Updating to latest version...</h1>
        <h3 className={`text-lg text-center text-blue-600`} ><a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            emptyCacheStorage();
          }}
        >Click here to Refresh </a> if the page doesn't refresh in the next couple of seconds.
        </h3>
      </>
    );
  }


  return (
    <>
      <Toaster toastOptions={{ position: 'top-right' }} />
      <RouterProvider router={router} />
    </>
  );
}

// export default App;

const Providers = ({ children }: { children: any }) => {
  return (
    <GlobalProvider>
      <UserProvider>
        {children}
      </UserProvider>
    </GlobalProvider>
  );
};

const Root = () => (
  <QueryClientProvider client={queryClient}>
    <Providers>
      <App />
    </Providers>
  </QueryClientProvider>
);
export default Root;


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

