import React, { ButtonHTMLAttributes, forwardRef } from "react";

import classNames from "classnames";
import { PropsColor } from "../common/colours";
import { Link } from "react-router-dom";


interface PropsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: PropsColor,
  outlined?: boolean,
  icon?: boolean,
  className?: string,
  link?: boolean,
  to?: string,
  target?: string,
  children: any,
}

const Button = forwardRef<HTMLButtonElement, PropsButton>((props, ref) => {
  const {
    color = "rhinoBlue",
    outlined,
    icon,
    className,
    children,
    link,
    to,
    target = "_self",
    ...rest
  } = props;

  if (to) {
    return (
      <Link
        // ref={ref}
        to={to}
        target={target}
        className={classNames(
          '',
          {
            "btn": !link,
            [`btn-${color}`]: !link,
            "link": link,
            "btn-outlined": outlined,
            "btn-icon btn_icon_large": icon,
          },
          className
        )
        }
      // {...rest}
      >
        {children}
      </Link >
    )
  }

  return (
    <button
      ref={ref}
      className={classNames(
        '',
        {
          "btn": !link,
          [`btn-${color}`]: !link,
          "link": link,
          "btn-outlined": outlined,
          "btn-icon btn_icon_large": icon,
        },
        className
      )
      }
      {...rest}
    >
      {children}
    </button >
  );
}
);

export default Button;
