import React from 'react';
import List from './List';
import { Route, Routes, useParams } from 'react-router-dom';
import Error from '../Errors';
import { AdminProvider } from '../../context/Administrator';
import AdminForm from './Form';


function AdminPage() {
    const { adminID } = useParams();

    return (
        <AdminProvider id={adminID} >
            <Routes>
                <Route errorElement={<Error fullscreen={false} />}>
                    <Route index element={<div className="card"><AdminForm /></div>} />
                    <Route path={"*"} element={<Error title={'Page not found'} fullscreen={false} hideButton />} />
                </Route>
            </Routes>
        </AdminProvider>
    )
}

export default AdminPage;