import React, { useEffect } from "react";
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver'

import axios from "axios";

import { urls } from "../../common";
import { NavigateFunction } from "react-router-dom";



export const handleAddCompany = (navigate: NavigateFunction) => {
    Swal.fire({
        title: 'Create New Company',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: (name) => {

            if (!name) {
                Swal.showValidationMessage(`Company Name Required`)
                return;
            }
            return axios.post(`${urls.remoteURL}company/`, { name })
                .then((x) => x.data)
                .catch((err) => {
                    Swal.showValidationMessage(`Problem Saving Company`)
                });

        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            console.log(result.value);

            Swal.fire({
                title: `${result.value.name} is saved`
            })
                .then(() => {
                    navigate(`/companies/${result.value.id}`)
                })
        }
    })
}


export const handleCompanyDeleteClick = (id: string, refetch: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}company/${id}`).then(() => {
                Swal.fire(
                    'Deleted!',
                    'The Company has been deleted.',
                    'success'
                );
                refetch();
            });

        }
    })

}




export const handleDownloadPolicy = (id: string) => {
    axios.get(
        `${urls.remoteURL}company/${id}/policy`,
        { responseType: 'blob' })
        .then(({ data }) => {
            if (data) {
                // const url = URL.createObjectURL(data);
                saveAs(data, 'policy.docx');
                // window.open(url, '_BLANK')?.focus();
            }
        })
        .catch(error => console.log(error));
}