import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from 'react-hot-toast';


import { useCompany } from "../../context/Company";
import { urls } from "../../common";

import { businessTypes, industryOptions } from "../../common/options";
import { FullCompany } from "../../types/company";
import LoadingDots from "../../components/LoadingDots";
import Button from "../../components/Button";
import Input from "../../components/form/Input";
import Label from "../../components/form/Label";
import { CustomSelectControl } from "../../components/form/CustomSelect";
import TextArea from "../../components/form/Textarea";

const CompanyForm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { company, setCompany } = useCompany();

    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const onSubmit = (data: FullCompany) => {
        setLoading(true);
        axios.put(`${urls.remoteURL}company/`, { ...data, id: company?.id })
            .then((x) => {
                // console.log(x.data);
                setLoading(false);
                setCompany((prev) => ({ ...prev, ...data }));
                toast.success('Company saved')
            })
            .catch((err) => {
                toast.error('Error Saving, please check you have filled everything in correctly.')
            })
            .finally(() => {
                setLoading(false);
            });
    }
    // const watchAllFields = watch();


    return (
        <div className="rounded-md bg-white shadow px-3 py-3 mx-3">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="border-gray-200 flex justify-between border-b mb-3 pb-2">
                    <h4 className={``}>Company Details</h4>
                    <div className="flex gap-2">
                        <Button
                            type="submit"
                            color="green"
                        >Save</Button>
                        <Button
                            to={'quotes'}
                            type="button"
                            color="rhinoBlue"
                        >Quotes</Button>
                        {/* <Button
                            to={'policy'}
                            type="button"
                            color="purple"
                        >Policy</Button> */}
                        {/* <Button
                            to={'../'}
                            type="button"
                            color="blue"
                        >Back</Button> */}
                    </div>
                </div>
                <div className=" mb-3">
                    <Label>Company Name</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.name}
                        required={true}
                        invalid={!!errors.name}
                        {...register('name', { required: true })}
                    />
                </div>
                <div className=" mb-3">
                    <Label>Address</Label>
                    <div className="space-y-2">
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={company?.address}
                            invalid={!!errors.address}
                            required={true}
                            {...register('address', { required: true })}
                        />
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={company?.address_2}
                            {...register('address_2')}
                        />
                    </div>
                    <Label>City</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.city}
                        required={true}
                        invalid={!!errors.city}
                        {...register('city', { required: true })}
                    />
                    <Label>County</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.county}
                        {...register('county')}
                    />
                    <Label>Postcode</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.postcode}
                        required={true}
                        invalid={!!errors.postcode}
                        {...register('postcode', { required: true })}
                    />
                    <Label>Country</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.country}
                        {...register('country')}
                    />
                </div>

                <div className="mb-3">
                    <Label>Additional Address</Label>
                    <TextArea
                        disabled={loading}
                        defaultValue={company?.additional_address}
                        {...register('additional_address')}
                    />
                </div>
                <div className="border-gray-200 border-b mb-3 pb-2">
                    <h4 className={``}>Business Details</h4>
                </div>
                <div className="mb-3">
                    <Label>Business Type</Label>
                    <CustomSelectControl
                        control={control}
                        name="business_type"
                        defaultValue={company?.business_type}
                    >
                        <option key='none' value={''}>Select Industry...</option>
                        {businessTypes.map((option) => (
                            <option key={'businessType_' + option.value} value={option.value}>{option.label}</option>
                        ))}
                    </CustomSelectControl>
                </div>
                <div className="mb-3">
                    <Label>Industry</Label>
                    <CustomSelectControl
                        control={control}
                        name="industry"
                        defaultValue={company?.industry}
                    >
                        <option key='none' value={''}>Select Industry...</option>
                        {industryOptions.map((option) => (
                            <option key={'industry' + option.value} value={option.value}>{option.label}</option>
                        ))}
                    </CustomSelectControl>
                </div>
                <div className="mb-3">
                    <Label>Currency</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.currency}
                        {...register('currency')}
                    />
                </div>

                <div className="border-gray-200 border-b mb-3 pb-2">
                    <h4 className={``}>Company Contact</h4>
                </div>
                <div className="mb-3">
                    <Label>Contact Name</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.contact}
                        {...register('contact')}
                    />
                </div>
                <div className="mb-3">
                    <Label>Contact Email</Label>
                    <Input
                        type="email"
                        disabled={loading}
                        defaultValue={company?.email}
                        invalid={!!errors.email}
                        {...register('email')}
                    />
                </div>
                <div className="mb-3">
                    <Label>Contact Telephone</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.tel}
                        {...register('tel')}
                    />
                </div>
                {/* <div className="mb-3">
                    <Label>Contact Position</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={company?.contact_position}
                        {...register('contact_position')}
                    />
                </div> */}

                
            </form>
        </div>
    )
}

export default CompanyForm;