import { useState, createContext, useContext } from 'react'

interface PropsGlobal {
    current?: string
}

interface globalContext {
    global: PropsGlobal;
    setGlobal: React.Dispatch<React.SetStateAction<PropsGlobal>>;
}

const GlobalContext = createContext<globalContext>({} as globalContext);

export const GlobalProvider = ({ children }: { children: any }) => {
    const [global, setGlobal] = useState<PropsGlobal>(
        {
            current: ''
        }
    );

    return (
        <GlobalContext.Provider value={{ global, setGlobal }}>
            {children}
        </GlobalContext.Provider >
    );
}

export const useGlobal = () => useContext(GlobalContext);