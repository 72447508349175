import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Link } from "react-router-dom";


// import logo from "/images/white-rhino.png";
// import logoSqr from "/images/white-rhino-sqr.png";
import { urls } from "../common";
import { useUser } from "../context/User";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useGlobal } from "../context/globals";

type navbarProps = {
    current?: string
}

const NavBar = ({ current }: navbarProps) => {
    const { user } = useUser();
    const { global } = useGlobal();

    const navigation = [
        { name: 'Dashboard', href: '/', current: !global.current || global.current === 'dashboard' },
        { name: 'Companies', href: '/companies', current: global.current === 'companies' },
        { name: 'Quote Generation', href: '/create-quote', current: global.current === 'create-quote' },
        { name: 'Services', href: '/services', current: global.current === 'services' },
        // { name: 'Calendar', href: '#', current: false },
        // { name: 'Reports', href: '#', current: false },
    ]
    const userNavigation = [
        { name: 'My Profile', href: `/administrators/${user?.id}` },
        { name: 'Administrators', href: '/administrators' },
        // { name: 'Maintenance', href: '/maintenance' },
        { name: 'Sign out', href: '/logout' },
    ]


    return (
        <Disclosure as="nav" className="bg-rhinoBlue-500 border-b border-rhinoBlue-300 border-opacity-25 lg:border-none">
            {({ open, close }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                        <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-rhinoBlue-400 lg:border-opacity-25">
                            <div className="px-2 flex items-center lg:px-0">
                                <div className="shrink-0">
                                    <img
                                        className="block h-8"
                                        src={'/images/white-rhino.png'}
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="hidden lg:block lg:ml-10">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-rhinoBlue-700 text-white'
                                                        : 'text-white hover:bg-rhinoBlue-500 hover:bg-opacity-75',
                                                    'rounded-md py-2 px-3 text-sm'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                                <div className="max-w-lg w-full lg:max-w-xs">
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="relative text-gray-400 focus-within:text-gray-600">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                            <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-rhinoBlue-600 focus:ring-white focus:border-white sm:text-sm"
                                            placeholder="Search"
                                            type="search"
                                            name="search"
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex lg:hidden  print:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="bg-rhinoBlue-600 p-2 rounded-md inline-flex items-center justify-center text-rhinoBlue-200 hover:text-white hover:bg-rhinoBlue-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-rhinoBlue-600 focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <FontAwesomeIcon icon={faXmark} className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <FontAwesomeIcon icon={faBars} className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="hidden lg:block lg:ml-4">
                                <div className="flex items-center">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative shrink-0">
                                        <div>
                                            <Menu.Button className="bg-rhinoBlue-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-rhinoBlue-600 focus:ring-white">
                                                <span className="sr-only">Open user menu</span>
                                                <img className="rounded-full h-8 w-8" src={!user?.profile ? '/images/white-rhino-sqr.png' : `${urls.remoteURL}${user.profile}`} alt="" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100 "
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {userNavigation.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({ active }) => (
                                                            <Link
                                                                to={item.href}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block py-2 px-4 text-sm text-gray-700 font-light'
                                                                )}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    onClick={() => close()}
                                    className={classNames(
                                        item.current
                                            ? 'bg-rhinoBlue-700 text-white'
                                            : 'text-white hover:bg-rhinoBlue-500 hover:bg-opacity-75',
                                        'block rounded-md py-2 px-3 text-base font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                        <div className="pt-4 pb-3 border-t border-rhinoBlue-700">
                            <div className="px-5 flex items-center">
                                <div className="shrink-0">
                                    <img className="rounded-full h-10 w-10" src={'/images/white-rhino-sqr.png'} alt="" />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-white">{user?.firstname}</div>
                                    <div className="text-sm font-medium text-rhinoBlue-300">{user?.email}</div>
                                </div>
                            </div>
                            <div className="mt-3 px-2 space-y-1">
                                {userNavigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        onClick={() => close()}
                                        className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-rhinoBlue-500 hover:bg-opacity-75"
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default NavBar;