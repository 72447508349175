import { FC, InputHTMLAttributes } from "react";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import classNames from "classnames";

interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean,
  className?: string,
  outlined?: boolean,
  label?: string,
}


const CheckboxControl = (props: UseControllerProps<FieldValues, string> & PropsInput) => {
  const { defaultChecked, className, label } = props;
  const {
    field: { value, onChange }
  } = useController(props);

  return (
    <label
      className={classNames("checkbox space-x-2", className)}
    >
      {label ? <span>{label}</span> : null}
      <input type="checkbox" defaultChecked={defaultChecked} onChange={onChange} className="w-6 h-6 rounded" checked={value} />
    </label>
  );
}

export const Checkbox = (props: { id: string, defaultChecked: boolean, setValue: React.Dispatch<React.SetStateAction<boolean>>, outlined?: boolean, className?: string, label?: string, value?: string, as?: React.ElementType, disabled?: boolean }) => {
  const { id, defaultChecked, setValue, outlined, className, label, value, as = 'label', disabled } = props;
  const As = as;
  return (
    <As
      className={classNames("switch", className)}
    >
      {label ? <span className="mr-2">{label}</span> : null}
      <input
        id={id} type="checkbox" checked={defaultChecked} value={value} disabled={disabled} onChange={(e) => { setValue(e.target.checked) }}
        className="h-6 w-6 rounded"

      />
      {/* <span onClick={() => setValue(prev => !prev)}></span> */}
    </As>
  );
}


export default CheckboxControl;
