import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Error from '../Errors';
import { CompanyProvider } from '../../context/Company';
import CompanyForm from './Form';
import QuoteList from '../Quotes/List';
import EditQuote from './EditQuote';
import CreateQuote from './CreateQuote';
import CompanyPolicyForm from './PolicyForm';


function CompanyPage() {
    const { companyID } = useParams();

    return (
        <CompanyProvider id={companyID} >
            <Routes>
                <Route errorElement={<Error fullscreen={false} />}>
                    <Route index element={<CompanyForm />} />
                    <Route path={'quotes'}>
                        <Route index element={<div className="card"><QuoteList company_id={companyID} /></div>} />
                        <Route path={':quoteID'} element={<EditQuote />} />
                    </Route>
                    <Route path={'create-quote'} element={<CreateQuote />} />
                    <Route path={'policy'} element={<CompanyPolicyForm />} />
                    <Route path={"*"} element={<Error title={'Page not found'} fullscreen={false} hideButton />} />
                </Route>
            </Routes>
        </CompanyProvider>
    )
}

export default CompanyPage;