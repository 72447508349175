import React, { useEffect } from 'react';
import { useGlobal } from '../../context/globals';
import { Outlet, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { handleAddCompany } from './common';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function CompanyWrapper() {
    const { setGlobal } = useGlobal();
    const navigate = useNavigate();

    useEffect(() => {
        setGlobal(prev => ({ ...prev, current: 'companies' }));
    }, []);


    return (
        <>
            <div className="bg-rhinoBlue-200 pb-32 print:pb-0">
                <header className="py-10 print:hidden">
                    <div className="flex justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 className="flex-1 text-3xl font-black text-white">Companies</h1>
                        <div>
                            <Button type="button"
                                color="green"
                                onClick={() => handleAddCompany(navigate)}
                            ><FontAwesomeIcon icon={faPlus} fixedWidth /> Add Company </Button>
                        </div>
                    </div>
                </header>
            </div>
            <div className="-mt-32 print:mt-6 grow w-full max-w-7xl mx-auto xl:px-8">
                <Outlet />
            </div>
        </>
    );
}

export default CompanyWrapper;