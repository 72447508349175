import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { useForm } from "react-hook-form";
import numeral from "numeral";
import axios from "axios";
import toast from 'react-hot-toast';

import { urls } from "../../common";

import { FullCompany } from "../../types/company";
import { FullQuote } from "../../types/quote";
import LoadingDots from "../../components/LoadingDots";
import Input from "../../components/form/Input";
import Label from "../../components/form/Label";
import TextArea from "../../components/form/Textarea";

type props = {
    inputID?: string,
    quote?: FullQuote,
    company?: FullCompany,
}

const EmailForm = ({ inputID, quote, company }: props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [json, setJSON] = useState({});

    const [name, setName] = useState(company?.contact);
    const [email, setEmail] = useState(company?.email);
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        setJSON({ name, email, message });
    }, [name, email, message])


    if (loading) {
        return (
            <LoadingDots />
        )
    }

    return (
        <form onSubmit={() => { }}>

            <div className=" mb-3 text-left ">
                <Label>Name</Label>
                <Input
                    type="text"
                    disabled={loading}
                    required={true}
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>



            <div className=" mb-3 text-left">
                <Label>Email</Label>
                <Input
                    type="email"
                    required={true}
                    disabled={loading}
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className=" mb-3 text-left">
                <Label>Message</Label>
                <TextArea
                    rows={4}
                    // defaultValue={}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>

            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    )

}

export default EmailForm;