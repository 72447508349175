import React from "react";
import axios from "axios";
import Swal from 'sweetalert2'

import { urls } from "../../common";
import { NavigateFunction } from "react-router-dom";

export const handleAddService = (navigate: NavigateFunction) => {
    Swal.fire({
        title: 'Create New Serrvice',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: (name) => {

            if (!name) {
                Swal.showValidationMessage("Name Required")
                return;
            }

            return axios.post(`${urls.remoteURL}services/`, { name })
                .then((x) => x.data)
                .catch((err) => {
                    Swal.showValidationMessage(`Problem Saving Service?`)
                });
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: `${result.value.name} is saved`
            })
                .then(() => {
                    navigate(`/services/${result.value.id}`)
                })
        }
    })
}

export const handleDeleteClick = (id: string, refetch: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}services/${id}`).then(() => {
                Swal.fire(
                    'Deleted!',
                    'The Service has been deleted.',
                    'success'
                );
                refetch();
            });

        }
    })

}

