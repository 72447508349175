export const travelOptions = [
    { value: 0, label: 'Less than 1 1/2 Hour' },
    { value: 1, label: '1 1/2 Hours' },
    { value: 2, label: '2 1/2 Hours' }
]

export const packageOptions = [
    { value: 0, label: 'Low Risk' },
    { value: 1, label: 'Medium Risk' },
    { value: 2, label: 'High Risk' }
]

export const commitmentOptions = [
    { value: 12, label: '1 Year' },
    { value: 24, label: '2 Year' },
    { value: 36, label: '3 Year' },
]


export const businessTypes = [
    { value: 'Sole Trader', label: 'Sole Trader' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'Limited', label: 'Limited' },
    { value: 'LLP', label: 'LLP' },
    { value: 'PLC', label: 'PLC' },
    { value: 'Charity', label: 'Charity' },
    { value: 'Other', label: 'Other' },
]

export const industryOptions = [
    { value: 'Office', label: 'Office' },
    { value: 'Construction/Contractor', label: 'Construction/Contractor' },
    { value: 'Engineering/Manufactoring', label: 'Engineering/Manufactoring' },
    { value: 'Childcare', label: 'Childcare' },
    { value: 'Hospitality', label: 'Hospitality' },
    { value: 'Warehousing', label: 'Warehousing' },
    { value: 'Other', label: 'Other' },
]