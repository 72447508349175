import React from 'react';
import { Outlet } from 'react-router-dom';

function PublicWrapper() {
    return (
        <div className="pt-6">
            <div className="card  print:mt-6 grow w-full max-w-7xl mx-auto p-0">
                <Outlet />
            </div>
        </div>
    )
}

export default PublicWrapper;