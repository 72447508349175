import React from "react";

import axios from "axios";
import { useQuery } from 'react-query'
import numeral from "numeral";

// import { SelectColumnFilter } from "../../components/ui/components/RTable";
import { urls } from "../../common";
import { formatDate } from "../../common/dates";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord, faPencil, faPlus, faPoundSign, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { handleCompanyDeleteClick } from "./common";
import classNames from "classnames";


const CompanyList = () => {
    const navigate = useNavigate();

    const { isLoading, isError, data, error, refetch } = useQuery(`companies`, () => axios.get(urls.remoteURL + `company/all`));


    const createdCell = (info: CellContext<TableProps, number>) => {
        return (
            <>
                {formatDate({ time: info.getValue(), formatStr: 'do MMM yyyy' })}
            </>
        );
    }

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="flex gap-2">
                {/* {value} */}
                <Button
                    to={`${info.getValue()}`}
                    color="green"
                // className={'rounded sm:p-1'}
                ><span className="sr-only">Edit Company</span>
                    <FontAwesomeIcon icon={faPencil} fixedWidth aria-hidden="true" /> </Button>
                <Button
                    to={`${info.getValue()}/quotes`}
                    color="blue"
                // className={'rounded sm:p-1'}
                ><span className="sr-only">View Quotes</span>
                    <FontAwesomeIcon icon={faPoundSign} fixedWidth aria-hidden="true" /> </Button>
                {/* <Button
                    to={`${info.getValue()}/policy`}
                    color="purple"
                // className={'rounded sm:p-1'}
                ><span className="sr-only">Policy</span>
                    <FontAwesomeIcon icon={faFileWord} fixedWidth aria-hidden="true" /> </Button> */}
                <Button
                    type="button"
                    color="red"
                    // className={'rounded sm:p-1'}
                    onClick={(e) => handleCompanyDeleteClick(info.getValue(), refetch)}><span className="sr-only">Delete Company</span>
                    <FontAwesomeIcon icon={faXmark} fixedWidth aria-hidden="true" /> </Button>
            </div>
        );
    }



    type TableProps = {
        id: string,
        name: string,
        email: string,
        contact: string,
        quotes: number,
        created: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            // cell: statusCell,
            header: 'Company',
            footer: 'Company',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('contact', {
            // cell: statusCell,
            header: 'Contact',
            footer: 'Contact',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('email', {
            // cell: statusCell,
            header: 'Owner',
            footer: 'Owner',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('quotes', {
            cell: (info) => numeral(info.getValue()).format('0,0'),
            header: 'Quotes',
            footer: 'Quotes',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('created', {
            cell: info => formatDate({ time: info.getValue() }),
            header: 'Created',
            footer: 'Created',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const rows = React.useMemo(() => {
        if (isLoading === false) {
            console.log(data);

            return Object.values(data?.data);
        } else {
            return [];
        }
    }, [data?.data]);

    return (
        <>{
            isLoading === false &&
            <Table columns={columns} defaultData={rows} />
        }
        </>
    )
}

export default CompanyList;