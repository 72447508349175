import React, { useEffect } from "react";
import Swal from 'sweetalert2'

import axios from "axios";
import { useQuery } from 'react-query'
import numeral from "numeral";

// import { SelectColumnFilter } from "../../components/ui/components/RTable";
import { urls } from "../../common";
import { formatDate } from "../../common/dates";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye, faPencil, faPlus, faPrint, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";


type pageProps = {
    company_id?: string
}

const QuoteList = ({ company_id }: pageProps) => {
    const navigate = useNavigate();

    const { isLoading, isError, data, error, refetch } = useQuery(`quotes-${company_id}`, () => axios.get(urls.remoteURL + (typeof company_id === 'undefined' ? "quotes/all" : `quotes/company/${company_id}`)));


    const createdCell = (info: CellContext<TableProps, number>) => {
        return (
            <>
                {formatDate({ time: info.getValue(), formatStr: 'do MMM yyyy' })}
            </>
        );
    }

    const statusCell = (info: CellContext<TableProps, string>) => {
        return (
            <>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full font-medium ${info.getValue() === 'declided' ? 'bg-red-100 text-red-800' :
                    info.getValue() === 'open' ? 'bg-rhinoBlue-100 text-rhinoBlue-800' :
                        'bg-green-100 text-green-800'}`
                }>{info.getValue()}</span>
            </>
        );
    }

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="flex gap-2">
                {/* {value} */}
                <Button type="submit"
                    color="green"
                    // className={'rounded sm:p-1'}
                    onClick={(e) => handleQuoteEditClick(info.row.original.company_id, info.row.original.id)}
                ><span className="sr-only">Edit Quote</span>
                    <FontAwesomeIcon icon={faPencil} fixedWidth aria-hidden="true" /> </Button>
                <Button type="button"
                    color="blue"
                    // className={'rounded sm:p-1'}
                    onClick={(e) => handleQuoteViewClick(info.row.original.company_id, info.getValue())}><span className="sr-only">View Quote</span>
                    <FontAwesomeIcon icon={faEye} fixedWidth aria-hidden="true" /> </Button>
                <Button type="button"
                    color="indigo"
                    // className={'rounded sm:p-1'}
                    onClick={(e) => handleCopyLinkClick(info.row.original.company_id, info.getValue())}><span className="sr-only">Copy Quote Link</span>
                    <FontAwesomeIcon icon={faCopy} fixedWidth aria-hidden="true" /> </Button>
                <Button type="button"
                    color="orange"
                    disabled={info.row.original.status !== 'accepted'}
                    // className={'rounded sm:p-1'}
                    onClick={(e) => handlePrintAccountsClick(info.row.original.company_id, info.getValue())}><span className="sr-only">Print Quote for Accounts</span>
                    <FontAwesomeIcon icon={faPrint} fixedWidth aria-hidden="true" /> </Button>{' '}
                <Button type="button"
                    color="red"
                    disabled={info.row.original.status === 'sent'}
                    // className={'rounded sm:p-1'}
                    onClick={(e) => handleQuoteDeleteClick(info.row.original.company_id, info.getValue())}><span className="sr-only">Delete Quote</span>
                    <FontAwesomeIcon icon={faXmark} fixedWidth aria-hidden="true" /> </Button>{' '}
            </div>
        );
    }

    const handleCopyLinkClick = (companyID: string, id: string) => {
        navigator.clipboard.writeText(`${window.location.protocol}/${window.location.host}/your-offer/${companyID}-${id}`);
        Swal.fire(
            'Link Copied!',
            'The Quote link has been copied.',
            'success'
        );
    }
    const handleQuoteEditClick = (id: string, quoteID: string) => { navigate(`/companies/${id}/quotes/${quoteID}`); }
    const handlePrintAccountsClick = (id: string, quoteID: string) => {
        axios.get(
            `${urls.remoteURL}quotes/${id}-${quoteID}/accounts-pdf`,
            { responseType: 'blob' })
            .then(({ data }) => {
                if (data) {
                    const url = URL.createObjectURL(data);
                    window.open(url, '_BLANK')?.focus();
                }
            })
            .catch(error => console.log(error));
    }
    const handleQuoteViewClick = (id: string, quoteID: string) => { navigate(`/view-quote/${id}-${quoteID}`); }
    const handleQuoteDeleteClick = (id: string, quoteID: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${urls.remoteURL}quotes/${id}/${quoteID}`).then(() => {
                    Swal.fire(
                        'Deleted!',
                        'The Quote has been deleted.',
                        'success'
                    );
                    refetch();
                });

            }
        })
    }

    type TableProps = {
        id: string,
        name: string,
        email: string,
        total: number,
        status: string,
        validDate: number,
        sent: number,
        company_id: string,
        currency: string,
        created: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        ... !company_id ? [
            columnHelper.accessor('name', {
                // cell: statusCell,
                header: 'Company',
                footer: 'Company',
                sortingFn: 'alphanumeric',
            }),

            columnHelper.accessor('email', {
                // cell: statusCell,
                header: 'Owner',
                footer: 'Owner',
                sortingFn: 'alphanumeric',
            }),
        ] : [],

        columnHelper.accessor('total', {
            cell: (info) => <>{info.row.original.currency}{numeral(info.getValue()).format('0,0.00')}</>,
            header: 'Total',
            footer: 'Total',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('status', {
            cell: statusCell,
            header: 'Status',
            footer: 'Status',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('validDate', {
            cell: createdCell,
            header: 'Valid Until',
            footer: 'Valid Until',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('sent', {
            cell: createdCell,
            header: 'Quote Sent',
            footer: 'Quote Sent',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const rows = React.useMemo(() => {
        if (isLoading === false) {
            console.log(data);

            return Object.values(data?.data);
        } else {
            return [];
        }
    }, [data?.data]);

    return (
        <>{
            isLoading === false &&
            <>
                <div className="border-gray-200 flex justify-between border-b mb-3 pb-2">
                    <h4 className={``}>Quotes</h4>
                    <div className="flex gap-2">
                        <Button type="button"
                            color="green"
                            onClick={() => { navigate(company_id ? `/companies/${company_id}/create-quote` : `/create-quote`); }}
                        ><FontAwesomeIcon icon={faPlus} fixedWidth /> Add Quote </Button>
                        <Button
                            type="button"
                            color="blue"
                            onClick={() => { navigate(`/companies`); }}>Back</Button>
                    </div>
                </div>
                <div className=" mb-3">
                    <Table columns={columns} defaultData={rows} />
                </div>
                {/* // JSON.stringify(data.data, null, 2) */}
            </>
        }
        </>
    )
}

export default QuoteList;