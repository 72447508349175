import { useState, createContext, useContext, useEffect } from 'react'
import { tokenID, urls } from "../common";
import axios, { AxiosError } from 'axios';
import LoadingDots from '../components/LoadingDots';
import { FullService } from '../types/service';


interface serviceContext {
  service: FullService | null;
  setService: React.Dispatch<React.SetStateAction<FullService | null>>;
}

const ServiceContext = createContext<serviceContext>({} as serviceContext);

export function ServiceProvider({ id, children }: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [service, setService] = useState<FullService | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  const context = { service, setService, setRefresh };


  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}services/${id}`)
        .then(({ data }) => {
          setService(data);
          setLoading(false)
        })
        .catch((e) => {
          setService({});
          setLoading(false)
        });
    } else {
      setLoading(false);
      setService({});
    }
  }, [id, refresh]);

  return (
    <ServiceContext.Provider value={context}>
      {loading ? <LoadingDots /> : children}
    </ServiceContext.Provider>
  );
}

export function useService() {
  return useContext(ServiceContext);
}