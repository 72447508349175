import React from 'react';
import {
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Navigate,
} from 'react-router-dom';
import Wrapper from './components/Wrapper';
import Error from './Pages/Errors';
import LoginPage from './Pages/Login';
import RequireAuth from './common/RequireAuth';
import LoggedInWrapper from './components/LoggedInWrapper';
import Dashboard from './Pages/Dashboard';
import QuoteForm from './Pages/Quotes/ServiceForm';
import SimpleWrapper from './components/SimpleWrapper';
import CompanyPage from './Pages/Companies';
import CompanyWrapper from './Pages/Companies/wrapper';
import CompanyList from './Pages/Companies/List';
import QuoteView from './Pages/Quotes/ViewQuote';
import ViewQuote from './Pages/Quotes/ViewQuote';
import PublicWrapper from './components/PublicWrapper';
import QuoteThankYou from './Pages/Quotes/ThankYou';
import ServicesWrapper from './Pages/Services/wrapper';
import ServicesList from './Pages/Services/List';
import ServicePage from './Pages/Services';
import AdminWrapper from './Pages/Administrators/wrapper';
import AdminList from './Pages/Administrators/List';
import AdminPage from './Pages/Administrators';
import Logout from './Pages/Logout';

const router = createBrowserRouter(createRoutesFromElements(
    <Route element={<Wrapper />} errorElement={<Error />}>
        <Route path={"login"} element={<LoginPage />} />
        <Route path={"logout"} element={<Logout />} />
        {/* http:/localhost:8021/your-offer/1kVQVxXdvB-px5XJDQDzB */}
        <Route path={"your-offer"} element={<PublicWrapper />}>
            <Route index element={<Error />} />
            <Route path={':quoteID'} element={<ViewQuote />} />
            <Route path={':quoteID/thank-you'} element={<QuoteThankYou />} />
        </Route>

        <Route path={'*'} element={<LoggedInWrapper><RequireAuth /></LoggedInWrapper>} errorElement={<Error />}>
            <Route index element={<Dashboard />} />
            <Route path={'create-quote'} element={<SimpleWrapper title="Generate Quote"><QuoteForm /></SimpleWrapper>} />
            <Route path="companies" element={<CompanyWrapper />}>
                <Route index element={<div className="card"><CompanyList /></div>} />
                <Route path={':companyID/*'} element={<CompanyPage />} />
            </Route>
            <Route path={'view-quote'} >
                <Route index element={<Navigate to={'../'} />} />
                <Route path={':quoteID'} element={<SimpleWrapper title="View Quote"><div className="card"><QuoteView admin={true} /></div></SimpleWrapper>} />
            </Route>
            <Route path="services" element={<ServicesWrapper />}>
                <Route index element={<div className="card"><ServicesList /></div>} />
                <Route path={':serviceID'} element={<ServicePage />} />
            </Route>
            <Route path="administrators" element={<AdminWrapper />}>
                <Route index element={<div className="card"><AdminList /></div>} />
                <Route path={':adminID'} element={<AdminPage />} />
            </Route>
        </Route>
    </Route >
));

export default router;