import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import LoadingDots from '../components/LoadingDots';
import { FullUser } from '../types/user';


interface adminContext {
  admin: FullUser | null;
  setAdmin: React.Dispatch<React.SetStateAction<FullUser | null>>;
}

const AdminContext = createContext<adminContext>({} as adminContext);

export function AdminProvider({ id, children }: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [admin, setAdmin] = useState<FullUser | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  const context = { admin, setAdmin, setRefresh };


  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}admin/${id}`)
        .then(({ data }) => {
          setAdmin(data);
          setLoading(false)
        })
        .catch((e) => {
          setAdmin({});
          setLoading(false)
        });
    } else {
      setLoading(false);
      setAdmin({});
    }
  }, [id, refresh]);

  return (
    <AdminContext.Provider value={context}>
      {loading ? <LoadingDots /> : children}
    </AdminContext.Provider>
  );
}

export function useAdmin() {
  return useContext(AdminContext);
}