import React, { useEffect } from 'react';
import QuoteList from '../Quotes/List';
import { useGlobal } from '../../context/globals';


function Dashboard() {
    const { setGlobal } = useGlobal();
    useEffect(() => {
        setGlobal(prev => ({ ...prev, current: '' }));
    }, []);
    
    return (
        <>
            <div className="bg-rhinoBlue-200 pb-32 print:pb-0">
                <header className="py-10 print:hidden">
                    <div className="flex justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 className="flex-1 text-3xl font-black text-white">{'Dashboard'}</h1>
                    </div>
                </header>
            </div>
            <div className="-mt-32 print:mt-6 grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
                <div className="flex-1 bg-white rounded-lg shadow px-3 py-3 mx-3">
                    <div className="rounded-lg">
                        <QuoteList />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;