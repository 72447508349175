import React, { useState } from "react";
import axios from "axios";
import {
    // Link,
    Route,
    Navigate,
    useLocation,
    // useHistory,
    useParams,
} from "react-router-dom";


import { urls } from "../../common";
import { useUser } from "../../context/User";
import Logout from "../Logout";

import Input from "../../components/form/Input";
import Button from "../../components/Button";
import Label from "../../components/form/Label";

// import logo from "/images/Rhino-logo-large.jpg";



const LoginPage = () => {
    const { user, setToken } = useUser();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userType] = useState("cp")
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    // const [staySignedIn, setStaySignedIn] = useState(false);


    if (user != null) {
        return <Navigate to={'../'} />;
    }

    const doLogin = (user: any) => {
        setToken(user.token);
    };

    const getLogin = (ev: { preventDefault: () => void; }) => {
        ev.preventDefault();
        setError("");
        if (email === "" || password === "") {
            setError("Email and Password are required.");
            return;
        }
        setLoading(true);
        axios.post(`${urls.remoteURL}auth/token`, { email, password, userType })
            .then((x) => {
                if (x.data.result) {
                    doLogin(x.data);
                } else {
                    setError("Invalid Email/Password.");
                }
            })
            .catch((err) => {
                setError("Invalid Email/Password.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="flex w-full h-screen content-center items-center">
            <div className={`max-w-full w-full bg-gray-100 py-4 lg:py-12`}>
                <div className={`ml-auto mr-auto max-w-full w-full lg:w-1000 xl:w-1200}`}>
                    <div className={''}>
                        <div className="flex flex-col md:flex-row ">
                            <div className="flex-1 text-center">
                                <img src={'/images/Rhino-logo-large.jpg'} className="px-8" alt="Rhino Safety" />
                            </div>
                            <div className="flex-1 self-center">

                                <form onSubmit={getLogin} className={`w-full p-4`}>
                                    <h1 className={``}>Login</h1>
                                    <Label>Email Address</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        // mb={3}
                                        disabled={loading}
                                        value={email}
                                        placeholder="Enter your email address..."
                                        onChange={(ev) => setEmail(ev.target.value)}
                                    />
                                    <Label>Password</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        // mb={3}
                                        placeholder="Enter your password"
                                        disabled={loading}
                                        value={password}
                                        onChange={(ev) => setPassword(ev.target.value)}
                                    />

                                    {error && (<p className={`flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1`}>{error}</p>)}
                                    <Button type="submit" className={'mt-4 self-end rounded'}
                                        color="blue"
                                    >Sign In</Button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;