import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
    FilterFn,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    PaginationState,
    getPaginationRowModel,
    getSortedRowModel,
    Row,
    useReactTable,
    RowData,
} from '@tanstack/react-table'

import {
    rankItem,
} from '@tanstack/match-sorter-utils'

import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, IconDefinition, faChevronDoubleLeft, faChevronLeft, faChevronRight, faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import Button from "./Button";
import Label from "./form/Label";
import Input from "./form/Input";

declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
        className: string,
        thClassName?: string,
    }
}


type tableProps = {
    defaultData: any[], columns: any[],
    options?: {
        hideSearch?: boolean,
        hideExport?: boolean,
        hideFooter?: boolean,
        compactFooter?: boolean,
        hideSelection?: boolean,
        search?: string,
        hiddenColumns?: string[],
        pageSize?: number,
        hidePageSize?: boolean,
        BulkButtons?: React.FC,
        selectable?: boolean, selectedRows?: string[], selectableFunction?: (data: string[]) => void,
        selectDisabled?: string[], selectDisabledIcon?: IconDefinition
    }

}

const Table = ({
    defaultData, columns,
    options = {
        pageSize: 10,
        selectDisabled: [],
    }
}: tableProps) => {

    const [globalFilter, setGlobalFilter] = useState<string>(options.search as string);
    const checkbox = useRef<HTMLInputElement>(null)
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState<boolean>(false)
    const [selectedRows, setSelectedRows] = useState<string[]>(options.selectedRows || []);

    let extraColumns = 0;
    if (options.selectable) { extraColumns++; }

    const colspan = columns.length + extraColumns;

    useLayoutEffect(() => {
        //defaultData.filter(x => { return !options.selectDisabled || options.selectDisabled.includes(x.id) }).length
        if (checkbox.current && options.selectable) {
            const isIndeterminate: boolean = (selectedRows.length > 0 && selectedRows.length < defaultData.length) ||
                (!!options.selectDisabled && options.selectDisabled.length > 0);
            const isChecked = selectedRows.length > 0 && (selectedRows.length + (options.selectDisabled?.length ?? 0)) >= defaultData.length
            setChecked(isChecked)
            setIndeterminate(!isChecked && isIndeterminate)
            checkbox.current.indeterminate = !isChecked && isIndeterminate
        }
        if (typeof options.selectableFunction === 'function') {
            options.selectableFunction(selectedRows);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (options.selectable) {
            setSelectedRows(options?.selectedRows || []);
        }
    }, [options?.selectedRows]);

    useLayoutEffect(() => {
        setGlobalFilter(options?.search as string);
    }, [options?.search]);

    useEffect(() => {
        table.setPageIndex(0);
    }, [globalFilter])

    function toggleAll() {
        if (options.selectable) {
            setSelectedRows(checked ? [] : defaultData.filter(x => !options.selectDisabled?.includes(x.id)).map(row => row.id))
            setChecked(!checked && !indeterminate)
            setIndeterminate(false)
        }
    }

    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: options.pageSize || 10,
        })

    const [pageCount, setPageCount] = useState(1);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    const table = useReactTable({
        data: defaultData || [],
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
            columnVisibility: options.hiddenColumns ? options.hiddenColumns.reduce((a: any, b: any) => { a[b] = false; return a }, {}) : [],
            pagination,
        },
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        autoResetPageIndex: false,
        sortingFns: {
            myCustomSorting: (rowA: any, rowB: any, columnId: any): number => {
                return rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1;
            }
        },
    })
    
    useEffect(() => {
        setPageCount(Math.ceil(table.getPrePaginationRowModel().rows.length / pageSize));
    }, [pageSize, table.getPrePaginationRowModel().rows])


    return (
        <>
            <div className="flex gap-x-2 items-center justify-between">
                <div className="flex gap-2 items-center">
                    <Label>Search: </Label>
                    <Input
                        placeholder={`${defaultData.length} records ...`}
                        value={globalFilter}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                </div>
                <CSVLink filename={"csv-download.csv"} data={defaultData.map((v, i) => v)}><Button  >Download CSV</Button></CSVLink>
            </div>
            <div className="mt-2 flex flex-col overflow-hidden">
                <div className="-my-2 overflow-x-auto  -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {
                                                options.selectable &&
                                                <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-rhinoBlue-600 focus:ring-rhinoBlue-500 sm:left-6"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                            }


                                            {headerGroup.headers.map(header => (
                                                <th
                                                    scope="col"
                                                    className={classNames(
                                                        `px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase `,
                                                        header.column.columnDef.meta?.className)}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        :
                                                        <div className={classNames(
                                                            `flex items-center justify-between group`,
                                                            header.column.columnDef.meta?.thClassName
                                                        )}>
                                                            <div
                                                                className={
                                                                    classNames(
                                                                        {
                                                                            ' cursor-pointer select-none items-center': header.column.getCanSort(),
                                                                        }
                                                                    )}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                                {{
                                                                    asc: <FontAwesomeIcon icon={faChevronUp} fixedWidth />,
                                                                    desc: <FontAwesomeIcon icon={faChevronDown} fixedWidth />,
                                                                }[header.column.getIsSorted() as string] ?? null}
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    {table.getRowModel().rows.length > 0 ? table.getRowModel().rows.map(row =>
                                        <tr key={row.id} className={``}>
                                            {
                                                options.selectable &&
                                                <td key={`select-${row.id}`} className={`relative w-12 px-6 sm:w-16 sm:px-8 `}>
                                                    {!options.selectDisabled?.includes(row.original.id) ?
                                                        <>
                                                            {selectedRows && selectedRows.includes(row.id) && (
                                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-rhinoBlue-600" />
                                                            )}
                                                            <input
                                                                type="checkbox"
                                                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-rhinoBlue-600 focus:ring-rhinoBlue-500 sm:left-6"
                                                                value={row.id}
                                                                checked={selectedRows && selectedRows.includes(row.original.id)}
                                                                onChange={(e) =>
                                                                    setSelectedRows(
                                                                        e.target.checked
                                                                            ? [...selectedRows, row.original.id]
                                                                            : selectedRows.filter((p) => p !== row.original.id)
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                        :
                                                        options.selectDisabledIcon ?
                                                            <>
                                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-orange-600" />
                                                                <FontAwesomeIcon icon={options.selectDisabledIcon} className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-600  sm:left-6" />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-orange-600" />
                                                                <input
                                                                    type="checkbox"
                                                                    disabled
                                                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-600  sm:left-6"
                                                                    defaultChecked
                                                                    readOnly
                                                                />
                                                            </>
                                                    }
                                                </td>
                                            }

                                            {
                                                row.getVisibleCells().map((cell: any) => (
                                                    <td key={cell.id} className={
                                                        classNames(
                                                            `px-6 py-4 whitespace-nowrap`,
                                                            cell.column.columnDef.meta?.className
                                                        )}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ) : <tr><td className="px-6 py-4 whitespace-nowrap" colSpan={columns.length} >No Records found....</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-3 flex items-center justify-between">
                <div className="flex-1 flex justify-between sm:hidden">
                    <Button
                        // classes={["relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 text-center"]}
                        color="white"
                        onClick={() => table.setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>Previous</Button>
                    <Button
                        // classes={["relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"]}
                        color="white"
                        onClick={() => table.setPageIndex(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>Next</Button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="flex gap-x-2 items-center">
                        <span className="text-sm text-gray-700">
                            Page <span className="font-medium">{pageIndex + 1}</span> of <span className="font-medium">{pageCount}</span>
                        </span>
                        <label>
                            <span className="sr-only">Items Per Page</span>
                            <select
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={pageSize}
                                onChange={(e) => {
                                    table.setPageSize(Number(e.target.value));
                                }}
                            >
                                {[5, 10, 20].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <button
                                className={"rounded-l-md rounded-r-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:cursor-not-allowed"}
                                color="white"
                                onClick={() => table.setPageIndex(0)}
                                disabled={pageIndex === 0}
                            >
                                <span className="sr-only">First</span>
                                <FontAwesomeIcon icon={faChevronDoubleLeft} fixedWidth aria-hidden="true" />
                            </button>
                            <button
                                className={"rounded-none relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:cursor-not-allowed"}
                                color="white"
                                onClick={() => table.setPageIndex(pageIndex - 1)}
                                disabled={pageIndex === 0}
                            >
                                <span className="sr-only">Previous</span>
                                <FontAwesomeIcon icon={faChevronLeft} fixedWidth aria-hidden="true" />
                            </button>
                            <button
                                className={" relative rounded-none inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:cursor-not-allowed"}
                                onClick={() => table.setPageIndex(pageIndex + 1)}
                                disabled={pageIndex === pageCount - 1}>
                                <span className="sr-only">Next</span>
                                <FontAwesomeIcon icon={faChevronRight} aria-hidden="true" />
                            </button>
                            <button
                                className={"rounded-r-md relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:cursor-not-allowed"}
                                onClick={() => table.setPageIndex(pageCount - 1)}
                                disabled={pageIndex === pageCount - 1}
                            >
                                <span className="sr-only">Last</span>
                                <FontAwesomeIcon icon={faChevronDoubleRight} fixedWidth aria-hidden="true" />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>

            {/* <div>
                <pre>
                    <code>{JSON.stringify(state, null, 2)}</code>
                </pre>
            </div> */}
        </>
    )
}

export default Table;




const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank, });
    return itemRank.passed
}
