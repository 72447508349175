import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Error from '../Errors';
import { ServiceProvider } from '../../context/Service';
import ServiceForm from './Form';


function ServicePage() {
    const { serviceID } = useParams();

    return (
        <ServiceProvider id={serviceID} >
            <Routes>
                <Route errorElement={<Error fullscreen={false} />}>
                    <Route index element={<div className="card"><ServiceForm /></div>} />
                    <Route path={"*"} element={<Error title={'Page not found'} fullscreen={false} hideButton />} />
                </Route>
            </Routes>
        </ServiceProvider>
    )
}

export default ServicePage;