import React from 'react';

const LoadingDots = () => {

  return (
    // <div className="flex items-center justify-center">
    <div className="w-10 h-6 flex justify-between items-end">
      <div className="w-3 h-3 bg-rhinoBlue-500 rounded-full animate-bounce" />
      <div className="w-3 h-3 bg-rhinoBlue-500 rounded-full animate-bounce" style={{ animationDelay: '200ms' }} />
      <div className="w-3 h-3 bg-rhinoBlue-500 rounded-full animate-bounce" style={{ animationDelay: '400ms' }} />
    </div>
    // </div>
  );
};

export default LoadingDots;