import React from 'react';

function SimpleWrapper({ title, children }: { title: string, children: JSX.Element }) {
    return (
        <>
            <div className="bg-rhinoBlue-200 pb-32 print:pb-0">
                <header className="py-10 print:hidden">
                    <div className="flex justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 className="flex-1 text-3xl font-black text-white">{title}</h1>
                    </div>
                </header>
            </div>
            <div className="-mt-32 print:mt-6 grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
                <div className="flex-1">
                    {children}
                </div>
            </div>
        </>
    )
}

export default SimpleWrapper;