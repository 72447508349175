import { useEffect } from "react";
import { useUser } from "../../context/User";
import { Navigate } from "react-router-dom";

const Logout = () => {
    const { user, setToken, setUser } = useUser();
    useEffect(() => {
        if (user != null) {
            setToken('');
            setUser(null);
        }
    });

    return (
       <Navigate to="login" />
    );
};

export default Logout;
