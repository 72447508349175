import React, { useEffect, useState } from "react";

const QuoteThankYou = () => {

    return (

        <div className="rounded-lg bg-white shadow ">
            <div className="mb-3">
                <div className="block sm:flex justify-between p-4">
                    <img src={'/images/blue-logo.png'} className="order-none sm:order-last h-auto sm:h-20 md:h-32" />
                    <h1 className="text-5xl sm:text-4xl md:text-6xl xl:text-8xl uppercase">Thank you<br /><span className=" outline-text">for accepting</span></h1>
                </div>
                <div className="border-b-2 border border-rhinoBlue-600 mx-2 mb-3" />
                <div className="block px-4 pb-4">
                    <h4 className="font-light">We're really looking forward to working with you...</h4>
                    <h4 className="font-light">We'll be in touch to confirm with you shortly.</h4>
                    <div className="">

                    </div>
                </div>
            </div>

        </div>
    )
}

export default QuoteThankYou;