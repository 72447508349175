import React from 'react';
import QuoteForm from '../Quotes/ServiceForm';
import { useCompany } from '../../context/Company';
import { FullCompany } from '../../types/company';


function CreateQuote() {
    const { company } = useCompany();
    return (
        <QuoteForm company={company as FullCompany} />
    )
}

export default CreateQuote;