import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import axios from "axios";
import toast from 'react-hot-toast';


import { useCompany } from "../../context/Company";
import { urls } from "../../common";

import { FullCompany } from "../../types/company";
import Button from "../../components/Button";
import Input from "../../components/form/Input";
import Label from "../../components/form/Label";
import TextArea from "../../components/form/Textarea";
import { handleDownloadPolicy } from "./common";
import CheckboxControl from "../../components/form/Checkbox";
import classNames from "classnames";
import CustomSelect from "../../components/form/CustomSelect";

const CompanyPolicyForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { company, setCompany } = useCompany();

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<FullCompany>(
        {
            defaultValues: {
                'policy_animals': company?.policy_animals,
                'policy_bedrails': company?.policy_bedrails,
                'policy_dsear': company?.policy_dsear,
                'policy_english': company?.policy_english,
                'policy_fire_safety': company?.policy_fire_safety,
                'policy_first_aid': company?.policy_first_aid,
                'policy_glass': company?.policy_glass,
                'policy_health_surveillance': company?.policy_health_surveillance,
                'policy_hot_works': company?.policy_hot_works,
                'policy_infection_control': company?.policy_infection_control,
                'policy_knives': company?.policy_knives,
                'policy_legionella': company?.policy_legionella,
                'policy_lifting_equipment': company?.policy_lifting_equipment,
                'policy_permits_to_work': company?.policy_permits_to_work,
                'policy_playground_equipment': company?.policy_playground_equipment,
                'policy_pressure_systems': company?.policy_pressure_systems,
                'policy_protection_of_the_public': company?.policy_protection_of_the_public,
                'policy_public_health': company?.policy_public_health,
                'policy_racking': company?.policy_racking,
                'policy_radiation': company?.policy_radiation,
                'policy_scaffolding_operations': company?.policy_scaffolding_operations,
                'policy_signs_and_notices': company?.policy_signs_and_notices,
                'policy_spa_equipment': company?.policy_spa_equipment,
                'policy_temporary_works': company?.policy_temporary_works,
                'policy_vehicles_and_driving': company?.policy_vehicles_and_driving,
                'policy_vibration': company?.policy_vibration,
                'policy_violence': company?.policy_violence,
                'policy_waste_management': company?.policy_waste_management,
                'policy_working_on_over_or_near_water': company?.policy_working_on_over_or_near_water,
                'policy_working_on_site': company?.policy_working_on_site,
            }
        }
    );
    const onSubmit = (data: FullCompany) => {
        setLoading(true);
        axios.put(`${urls.remoteURL}company/`, { ...data, id: company?.id })
            .then((x) => {
                // console.log(x.data);
                setLoading(false);
                setCompany((prev) => ({ ...prev, ...data }));
                toast.success('Company saved')
            })
            .catch((err) => {
                toast.error('Error Saving, please check you have filled everything in correctly.')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const watchAllFields = useWatch({
        control,
        defaultValue: {
            'policy_animals': company?.policy_animals,
            'policy_bedrails': company?.policy_bedrails,
            'policy_dsear': company?.policy_dsear,
            'policy_english': company?.policy_english,
            'policy_fire_safety': company?.policy_fire_safety,
            'policy_first_aid': company?.policy_first_aid,
            'policy_glass': company?.policy_glass,
            'policy_health_surveillance': company?.policy_health_surveillance,
            'policy_hot_works': company?.policy_hot_works,
            'policy_infection_control': company?.policy_infection_control,
            'policy_knives': company?.policy_knives,
            'policy_legionella': company?.policy_legionella,
            'policy_lifting_equipment': company?.policy_lifting_equipment,
            'policy_playground_equipment': company?.policy_playground_equipment,
            'policy_pressure_systems': company?.policy_pressure_systems,
            'policy_protection_of_the_public': company?.policy_protection_of_the_public,
            'policy_public_health': company?.policy_public_health,
            'policy_racking': company?.policy_racking,
            'policy_radiation': company?.policy_radiation,
            'policy_scaffolding_operations': company?.policy_scaffolding_operations,
            'policy_signs_and_notices': company?.policy_signs_and_notices,
            'policy_spa_equipment': company?.policy_spa_equipment,
            'policy_vehicles_and_driving': company?.policy_vehicles_and_driving,
            'policy_vibration': company?.policy_vibration,
            'policy_violence': company?.policy_violence,
            'policy_waste_management': company?.policy_waste_management,
            'policy_working_on_over_or_near_water': company?.policy_working_on_over_or_near_water,
            'policy_working_on_site': company?.policy_working_on_site,
        }
    });

    const [options, setOptions] = useState<{
        name: string, label: string, value: boolean,
        extras?: {
            name: string, label: string, value: boolean | string, type: 'checkbox' | 'text' | 'textarea',
            placeholder: string,
            help?: string,
        }[]
    }[]>([
        {
            name: 'policy_animals', label: 'Animals', value: !!company?.policy_animals,
            extras: [
                { name: 'policy_animals_types', label: 'Types', value: company?.policy_animals_types as string, type: 'text', placeholder: 'small animals, livestock & equines' },
            ]
        },
        { name: 'policy_asbestos', label: 'Asbestos', value: !!company?.policy_asbestos, },
        {
            name: 'policy_bedrails', label: 'Bedrails', value: !!company?.policy_bedrails,
            extras: [
                { name: 'policy_bedrails_home_manager', label: 'Home Manager', value: company?.policy_bedrails_home_manager as string, type: 'text', placeholder: 'Home Manager' },
                { name: 'policy_bedrails_primary_nurse', label: 'Primary Nurse', value: company?.policy_bedrails_primary_nurse as string, type: 'text', placeholder: 'Primary nurses' },
                { name: 'policy_bedrails_management', label: 'Management', value: company?.policy_bedrails_management as string, type: 'text', placeholder: 'Management' },
            ]
        },
        { name: 'policy_behavioural_safety', label: 'Behavioural Safety', value: !!company?.policy_behavioural_safety, },
        { name: 'policy_butchery', label: 'Butchery', value: !!company?.policy_butchery, },
        { name: 'policy_cleaning', label: 'Cleaning', value: !!company?.policy_cleaning, },
        { name: 'policy_confined_spaces', label: 'Confined Spaces', value: !!company?.policy_confined_spaces, },
        { name: 'policy_construction', label: 'Construction', value: !!company?.policy_construction, },
        { name: 'policy_contractor', label: 'Contractor Supply Chain ', value: !!company?.policy_contractor, },
        { name: 'policy_coshh', label: 'C.O.S.H.H. ', value: !!company?.policy_coshh, },
        { name: 'policy_cylinder_handling', label: 'Cylinder Handling', value: !!company?.policy_cylinder_handling, },
        { name: 'policy_display_screen_equipment', label: 'Display Screen Equipment', value: !!company?.policy_display_screen_equipment, },
        { name: 'policy_drugs_and_alcohol', label: 'Drugs and Alcohol', value: !!company?.policy_drugs_and_alcohol, },
        {
            name: 'policy_dsear', label: 'DSEAR', value: !!company?.policy_dsear,
            extras: [
                { name: 'policy_dsear_areas', label: 'Areas are classified via Hazardous Area Classification as required', value: company?.policy_dsear_areas as string, type: 'text', placeholder: 'Areas are classified via Hazardous Area Classification as required, and the following suitable control measures implemented:' },
                { name: 'policy_dsear_areas_zones', label: 'Zones', value: company?.policy_dsear_areas_zones as string, type: 'textarea', placeholder: 'Zone 0 (Zone 20 for dusts): This is an area where an explosive atmosphere is continuously present, or present for long periods; e.g., the area above a flammable liquid within a tank would be classed as Zone 0.\nZone 1 (Zone 21 for dusts): This is an area where an explosive atmosphere is likely to occur only occasionally during normal operation; e.g., the environment immediately around the location where bulk decanting of flammable liquids takes place.\nZone 2 (Zone 22 for dusts): This is an area where an explosive atmosphere is not likely to occur in normal operation and, if it does, will persist for a short period only; e.g., a room or compartment in which bulk decanting takes place.', help: 'One per line' },
            ]

        },
        { name: 'policy_electrical_equipment', label: 'Electrical Equipment', value: !!company?.policy_electrical_equipment, },
        { name: 'policy_emergency_procedures', label: 'Emergency Procedures', value: !!company?.policy_emergency_procedures, },
        { name: 'policy_employees_tools', label: 'Employees Tools', value: !!company?.policy_employees_tools, },
        {
            name: 'policy_english', label: 'English as an Additional Language ', value: !!company?.policy_english,
            extras: [
                { name: 'policy_english_manager', label: 'Manager', value: company?.policy_english_manager as string, type: 'text', placeholder: 'The Manager' }
            ]
        },
        { name: 'policy_excavations', label: 'Excavations', value: !!company?.policy_excavations, },
        {
            name: 'policy_fire_safety', label: 'Fire Safety', value: !!company?.policy_fire_safety,
            extras: [
                { name: 'policy_fire_safety_management', label: 'Senior Management', value: company?.policy_fire_safety_management as string, type: 'text', placeholder: 'Senior Management' },
                { name: 'policy_fire_safety_year', label: 'Year', value: company?.policy_fire_safety_year as string, type: 'text', placeholder: 'Year' },
            ]


        },
        { name: 'policy_firearms', label: 'Firearms', value: !!company?.policy_firearms, },
        { name: 'policy_fireworks', label: 'Fireworks', value: !!company?.policy_fireworks, },
        {
            name: 'policy_first_aid', label: 'First Aid', value: !!company?.policy_first_aid,
            extras: [
                { name: 'policy_first_aid_location', label: 'Location', value: company?.policy_first_aid_location as string, type: 'text', placeholder: 'Location' },
            ]
        },
        { name: 'policy_food_hygiene', label: 'Food Hygiene & Kitchen Procedures', value: !!company?.policy_food_hygiene, },
        { name: 'policy_garage', label: 'Garage Equipment', value: !!company?.policy_garage, },
        { name: 'policy_general_premises', label: 'General Premises Conditions', value: !!company?.policy_general_premises, },
        {
            name: 'policy_glass', label: 'Glass', value: !!company?.policy_glass,
            extras: [
                { name: 'policy_glass_stored', label: 'Glass Storage', value: company?.policy_glass_stored as string, type: 'text', placeholder: 'in the glass racks provided and nowhere else' },
                { name: 'policy_glass_type', label: 'Glass Type', value: company?.policy_glass_type as string, type: 'text', placeholder: 'sheets' },
                { name: 'policy_glass_weather', label: 'Weather Conditions', value: company?.policy_glass_weather as string, type: 'text', placeholder: 'Weather conditions must be taken into account when loading and unloading vehicles.' },
            ]
        },
        { name: 'policy_golf_course', label: 'Golf Course Safety', value: !!company?.policy_golf_course, },
        { name: 'policy_gym', label: 'Gym & Exercise Equipment', value: !!company?.policy_gym, },
        { name: 'policy_hand_tools', label: 'Hand Tools', value: !!company?.policy_hand_tools, },
        { name: 'policy_health_hazards', label: 'Health Hazards', value: !!company?.policy_health_hazards, },
        {
            name: 'policy_health_surveillance', label: 'Health Surveillance', value: !!company?.policy_health_surveillance,
            extras: [
                { name: 'policy_health_surveillance_organises', label: 'Company Organises', value: company?.policy_health_surveillance_organises as string, type: 'text', placeholder: 'organises suitable health surveillance for all relevant members of staff' },
                { name: 'policy_health_surveillance_spot_checks', label: 'Spot Checks', value: company?.policy_health_surveillance_spot_checks as string, type: 'text', placeholder: 'Spot checks are carried out on a regular basis to check noise levels and dust levels. These are carried out at different times of the day' },
                { name: 'policy_health_surveillance_audit', label: 'Audits', value: company?.policy_health_surveillance_audit as string, type: 'text', placeholder: 'An audit is carried out on a quarterly basis to check noise and dust levels' },
            ]
        },
        {
            name: 'policy_hot_works', label: 'Hot Works', value: !!company?.policy_hot_works,
            extras: [
                { name: 'policy_hot_works_common', label: 'Common Industry', value: company?.policy_hot_works_common as string, type: 'text', placeholder: 'construction' },
            ]
        },
        {
            name: 'policy_infection_control', label: 'Infection Control', value: !!company?.policy_infection_control,
            extras: [
                { name: 'policy_infection_control_manager', label: 'Manager', value: company?.policy_infection_control_manager as string, type: 'text', placeholder: 'Manager' },
                { name: 'policy_infection_control_home_manager', label: 'Home Manager', value: company?.policy_infection_control_home_manager as string, type: 'text', placeholder: 'The Home Manager' },
                { name: 'policy_infection_control_home', label: 'Home', value: company?.policy_infection_control_home as string, type: 'text', placeholder: 'Home' },
                { name: 'policy_infection_control_professionals_agencies', label: 'Professionals and Agencies', value: company?.policy_infection_control_professionals_agencies as string, type: 'text', placeholder: 'Health Care professionals and Agencies' },
                { name: 'policy_infection_control_nurse', label: 'Nurse in Charge', value: company?.policy_infection_control_nurse as string, type: 'text', placeholder: 'Nurse in Charge' },
                { name: 'policy_infection_control_professionals', label: 'Professionals to be contacted', value: company?.policy_infection_control_professionals as string, type: 'textarea', help: 'One per line', placeholder: 'The Community Infection Control Nurse.\nGeneral Practitioner.\nThe Environmental Health Officer.\nThe CSCI.\nThe Consultant in Communicable Disease Control.' },
                { name: 'policy_infection_control_ppe', label: 'PPE', value: company?.policy_infection_control_ppe as string, type: 'text', placeholder: 'Disposable white plastic aprons must be worn over uniforms, and disposable gloves' },
                { name: 'policy_infection_control_staff', label: 'Service users or Staff', value: company?.policy_infection_control_staff as string, type: 'text', placeholder: 'the Service Users or staff' },
            ]
        },
        { name: 'policy_international_travel', label: 'International Travel', value: !!company?.policy_international_travel, },
        {
            name: 'policy_knives', label: 'Knives', value: !!company?.policy_knives,
            extras: [
                { name: 'policy_knives_manager', label: 'Manager', value: company?.policy_knives_manager as string, type: 'text', placeholder: 'The Manager' },
                { name: 'policy_knives_safety', label: 'Safety Knifes', value: company?.policy_knives_safety as string, type: 'text', placeholder: 'safety knives' },
            ]
        },
        {
            name: 'policy_legionella', label: 'Legionella', value: !!company?.policy_legionella,
            extras: [
                { name: 'policy_legionella_sources', label: 'Potential sources', value: company?.policy_legionella_sources as string, type: 'textarea', help: 'One per line', placeholder: '`Any of the showers and taps within our premises.\nAreas in the building where the pipework is long, resulting in the water temperature dropping below 45°C.\nAny redundant pipework in the building, or any areas where stagnant water can collect and build up.\nThe hot water tanks within the building.' },
                { name: 'policy_legionella_faucets', label: 'Faucets', value: company?.policy_legionella_faucets as string, type: 'text', placeholder: 'taps or showers' },
                { name: 'policy_legionella_sampling', label: 'Sampling', value: company?.policy_legionella_sampling as string, type: 'text', placeholder: 'SAMPLING' },
                { name: 'policy_legionella_sampling_text', label: 'Sampling Paragraph', value: company?.policy_legionella_sampling_text as string, type: 'textarea', placeholder: 'The Manager will take annual samples from different outlets around the premises, to include hot water tanks, to identify potential sources of legionella. These samples will be delivered to an accredited laboratory service, to be checked for the presence of the legionella bacteria. Records of these tests will be kept for inspection, for the statutory five-year period.' },
            ]
        },
        { name: 'policy_liaison_with_sa', label: 'Liaison With Statutory Authorities ', value: !!company?.policy_liaison_with_sa, },
        {
            name: 'policy_lifting_equipment', label: 'Lifting Equipment ', value: !!company?.policy_lifting_equipment,
            extras: [
                { name: 'policy_lifting_equipment_such_as', label: 'Such as', value: company?.policy_lifting_equipment_such_as as string, type: 'text', placeholder: 'equipment' },
                { name: 'policy_lifting_equipment_ride_on', label: 'Ride-On Material Handling Equipment', value: company?.policy_lifting_equipment_ride_on as string, type: 'text', placeholder: 'FORKLIFT TRUCKS & LOADALLS' },
                { name: 'policy_lifting_equipment_forklift_and_mhe', label: 'forklift trucks and materials handling equipment (MHE)', value: company?.policy_lifting_equipment_forklift_and_mhe as string, type: 'text', placeholder: 'forklift trucks and materials handling equipment (MHE)' },
                { name: 'policy_lifting_equipment_forklift_manager', label: 'Manager', value: company?.policy_lifting_equipment_forklift_manager as string, type: 'text', placeholder: 'The Manager' },
                { name: 'policy_lifting_equipment_forklift_or_mhe', label: 'forklift trucks or (MHE)', value: company?.policy_lifting_equipment_forklift_or_mhe as string, type: 'text', placeholder: 'forklift truck or MHE' },
                { name: 'policy_lifting_equipment_forklift', label: 'forklift trucks', value: company?.policy_lifting_equipment_forklift as string, type: 'text', placeholder: 'forklift trucks' },
                { name: 'policy_lifting_equipment_fork_attachments', label: 'forks/attachments', value: company?.policy_lifting_equipment_fork_attachments as string, type: 'text', placeholder: 'forks/attachments' },
                { name: 'policy_lifting_equipment_hiabs_title', label: 'HIABS and Cranes Title', value: company?.policy_lifting_equipment_hiabs_title as string, type: 'text', placeholder: 'HIABS & CRANES' },
                { name: 'policy_lifting_equipment_hiabs', label: 'HIABS and Cranes', value: company?.policy_lifting_equipment_hiabs as string, type: 'text', placeholder: 'hiabs & cranes' },
                { name: 'policy_lifting_equipment_hiabs_manager', label: 'Manager', value: company?.policy_lifting_equipment_hiabs_manager as string, type: 'text', placeholder: 'a manager' },
                { name: 'policy_lifting_equipment_lifts_title', label: 'Lifts Goods & Passenger Title', value: company?.policy_lifting_equipment_lifts_title as string, type: 'text', placeholder: 'Goods & Passenger' },
                { name: 'policy_lifting_equipment_lifts_rescue', label: 'Lifts Rescue plan including', value: company?.policy_lifting_equipment_lifts_rescue as string, type: 'text', placeholder: 'including the availability of an emergency two-way communication system' },
                { name: 'policy_lifting_equipment_lifts_management', label: 'Management', value: company?.policy_lifting_equipment_lifts_management as string, type: 'text', placeholder: 'management' },
            ]
        },
        { name: 'policy_lone_working', label: 'Lone Working', value: !!company?.policy_lone_working, },
        { name: 'policy_machinery', label: 'Machinery', value: !!company?.policy_machinery, },
        { name: 'policy_manual_handling', label: 'Manual Handling', value: !!company?.policy_manual_handling, },
        { name: 'policy_mental_health', label: 'Mental Health & Wellbeing', value: !!company?.policy_mental_health, },
        { name: 'policy_mobile_plant', label: 'Mobile Plant', value: !!company?.policy_mobile_plant, },
        { name: 'policy_monitoring', label: 'Monitoring, Auditing and Review', value: !!company?.policy_monitoring, },
        { name: 'policy_noise', label: 'Noise', value: !!company?.policy_noise, },
        {
            name: 'policy_permits_to_work', label: 'Permits To Work', value: !!company?.policy_permits_to_work,
            extras: [
                { name: 'policy_permits_to_work_premises', label: 'Premises or on site', value: company?.policy_permits_to_work_premises as string, type: 'text', placeholder: 'at our premises or on site' },
                { name: 'policy_permits_to_work_offsite', label: 'Employees working off site', value: company?.policy_permits_to_work_offsite as string, type: 'textarea', placeholder: 'Employees working off site, i.e. on another organisation’s premises, are expected to abide by all permits to work operated on that site. If no such permits are in use, employees must operate permit procedures as they exist under their employer. If additional permits are deemed necessary for certain off-site work, this should be raised with the appropriate person and the need for the permit determined.' },
            ]
        },
        { name: 'policy_personal_electrical_equipment', label: 'Personal Electrical Equipment', value: !!company?.policy_personal_electrical_equipment, },
        { name: 'policy_personal_protective_equipment', label: 'Personal Protective Equipment', value: !!company?.policy_personal_protective_equipment, },
        {
            name: 'policy_playground_equipment', label: 'Playground Equipment', value: !!company?.policy_playground_equipment,
            extras: [
                { name: 'policy_playground_equipment_gm', label: 'General Manager', value: company?.policy_playground_equipment_gm as string, type: 'text', placeholder: 'the General Manager' },
            ]
        },
        { name: 'policy_portable_appliance_testing', label: 'Portable Appliance Testing', value: !!company?.policy_portable_appliance_testing, },
        { name: 'policy_portable_electric_tools', label: 'Portable Electric Tools', value: !!company?.policy_portable_electric_tools, },
        {
            name: 'policy_pressure_systems', label: 'Pressure Systems', value: !!company?.policy_pressure_systems,
            extras: [
                { name: 'policy_pressure_systems_use', label: 'Use', value: company?.policy_pressure_systems_use as string, type: 'text', placeholder: 'use gas cylinders, portable compressors, pressure cleaning equipment and other pressure systems' },
            ]

        },
        {
            name: 'policy_protection_of_the_public', label: 'Protection Of The Public ', value: !!company?.policy_protection_of_the_public,
            extras: [
                { name: 'policy_protection_of_the_public_children', label: 'This may include children', value: company?.policy_protection_of_the_public_children as string, type: 'text', placeholder: 'this may include children' },
                { name: 'policy_protection_of_the_public_premises', label: 'Premises', value: company?.policy_protection_of_the_public_premises as string, type: 'text', placeholder: 'the premises ' },
                { name: 'policy_protection_of_the_public_basis', label: 'Regular Basis', value: company?.policy_protection_of_the_public_basis as string, type: 'text', placeholder: 'a regular basis to purchase items' },
                { name: 'policy_protection_of_the_public_plant', label: 'Plant & Vehicle', value: company?.policy_protection_of_the_public_plant as string, type: 'text', placeholder: 'Plant and vehicles must never be left unattended with the keys in the ignition' },
            ]

        },
        {
            name: 'policy_public_health', label: 'Public Health ', value: !!company?.policy_public_health,
            extras: [
                { name: 'policy_public_health_management', label: 'Management ', value: company?.policy_public_health_management as string, type: 'text', placeholder: 'Management ' },
            ]
        },
        {
            name: 'policy_racking', label: 'Racking', value: !!company?.policy_racking,
            extras: [
                { name: 'policy_racking_management', label: 'Management ', value: company?.policy_racking_management as string, type: 'text', placeholder: 'Management ' },
                { name: 'policy_racking_premises', label: 'Premises ', value: company?.policy_racking_premises as string, type: 'text', placeholder: 'premises ' },
            ]
        },
        {
            name: 'policy_radiation', label: 'Radiation', value: !!company?.policy_radiation,
            extras: [
                { name: 'policy_radiation_employees', label: 'Employees ', value: company?.policy_radiation_employees as string, type: 'text', placeholder: 'employees' },
            ]
        },
        { name: 'policy_recording_accidents', label: 'Recording Accidents', value: !!company?.policy_recording_accidents, },
        { name: 'policy_remote_working', label: 'Remote Working', value: !!company?.policy_remote_working, },
        { name: 'policy_riddor', label: 'R.I.D.D.O.R.', value: !!company?.policy_riddor, },
        { name: 'policy_risk_assessments', label: 'Risk Assessments', value: !!company?.policy_risk_assessments, },
        { name: 'policy_safeguarding', label: 'Safeguarding', value: !!company?.policy_safeguarding, },
        {
            name: 'policy_scaffolding_operations', label: 'Scaffolding Operations', value: !!company?.policy_scaffolding_operations,
            extras: [
                { name: 'policy_scaffolding_design_engineers', label: 'Design Engineers', value: company?.policy_scaffolding_design_engineers as string, type: 'text', placeholder: 'Design Engineers' },
            ]
        },
        {
            name: 'policy_signs_and_notices', label: 'Signs and Notices', value: !!company?.policy_signs_and_notices,
            extras: [
                { name: 'policy_signs_and_notices_main_office', label: 'Main office ', value: company?.policy_signs_and_notices_main_office as string, type: 'text', placeholder: 'Main office' },
                { name: 'policy_signs_and_notices_prominent', label: 'Prominent', value: company?.policy_signs_and_notices_prominent as string, type: 'text', placeholder: 'Prominent' },
            ]
        },
        { name: 'policy_silica_dust', label: 'Silica Dust', value: !!company?.policy_silica_dust, },
        {
            name: 'policy_spa_equipment', label: 'Spa Equipment', value: !!company?.policy_spa_equipment,

        },
        { name: 'policy_street_works', label: 'Street Works', value: !!company?.policy_street_works, },
        {
            name: 'policy_temporary_works', label: 'Temporary Works', value: !!company?.policy_temporary_works,
            extras: [
                { name: 'policy_temporary_works_design_engineers', label: 'Design Engineers', value: company?.policy_temporary_works_design_engineers as string, type: 'text', placeholder: 'Design Engineers' },
            ]
        },
        { name: 'policy_training_supervision', label: 'Training & Supervision', value: !!company?.policy_training_supervision, },
        { name: 'policy_underground_overhead_services', label: 'Underground / Overhead Services', value: !!company?.policy_underground_overhead_services, },
        {
            name: 'policy_vehicles_and_driving', label: 'Vehicles And Driving', value: !!company?.policy_vehicles_and_driving,
            extras: [
                { name: 'policy_vehicles_and_driving_customers_vehicles', label: 'customer\'s vehicles', value: company?.policy_vehicles_and_driving_customers_vehicles as string, type: 'text', placeholder: 'customer\'s vehicles' },
            ]
        },
        { name: 'policy_ventilation_extraction_systems', label: 'Ventilation / Extraction Systems', value: !!company?.policy_ventilation_extraction_systems, },
        {
            name: 'policy_vibration', label: 'Vibration', value: !!company?.policy_vibration,
            extras: [
                { name: 'policy_vibration_heavy_vechicles', label: 'Heavy Vechicles', value: company?.policy_vibration_heavy_vechicles as string, type: 'text', placeholder: 'Plant Lorries, Trenchers, Mole ploughs, etc' },
            ]
        },
        {
            name: 'policy_violence', label: 'Violence', value: !!company?.policy_violence,
            extras: [
                { name: 'policy_violence_deal_with', label: 'Staff who deal with', value: company?.policy_violence_deal_with as string, type: 'text', placeholder: 'with members of the public or visitors or residents' },
            ]
        },
        { name: 'policy_vulnerable_persons', label: 'Vulnerable Persons', value: !!company?.policy_vulnerable_persons, },
        {
            name: 'policy_waste_management', label: 'Waste Management', value: !!company?.policy_waste_management,
            extras: [
                { name: 'policy_waste_management_management', label: 'Management', value: company?.policy_waste_management_management as string, type: 'text', placeholder: 'management' },
                { name: 'policy_waste_management_clinical_waste', label: 'Clinical Waste', value: company?.policy_waste_management_clinical_waste as string, type: 'text', placeholder: 'Clinical Waste: Clinical waste is divided into categories, which are collected and stored separately.' },
                { name: 'policy_waste_management_clinical_waste_groups', label: 'Clinical Waste Groups', value: company?.policy_waste_management_clinical_waste_groups as string, type: 'textarea', placeholder: `GROUP A: All human tissue including blood and all related swabs and dressings. Waste materials where assessment indicates risk of handling them, for example from infectious diseases. This type of waste is collected in a plain YELLOW BAG.\nGROUP B: Discarded syringe needles, glass ampoules and any other contaminated disposable sharp instruments or items. The type of waste is placed in the YELLOW SHARPS BOX.\nGROUP C: Incontinence pads, stoma bags and catheter products. This type of waste is placed into YELLOW BAGS with a BLACK STRIPE on the side.`, help: `One per line.` },
                { name: 'policy_waste_management_other', label: 'Other', value: company?.policy_waste_management_other as string, type: 'textarea', placeholder: 'ANY OTHER SPECIFIC WASTE INSERT HERE i.e. sharps, needles', help: `One per line.` },
            ]
        },
        { name: 'policy_work_equipment', label: 'Work Equipment', value: !!company?.policy_work_equipment, },
        { name: 'policy_working_at_height', label: 'Working At Height', value: !!company?.policy_working_at_height, },
        {
            name: 'policy_working_on_over_or_near_water', label: 'Working On, Over or Near Water', value: !!company?.policy_working_on_over_or_near_water,
            extras: [
                { name: 'policy_working_on_over_or_near_water_management_controls', label: 'Management Controls', value: company?.policy_working_on_over_or_near_water_management_controls as string, type: 'textarea', placeholder: `We establish Safe Systems of Work (SSoW) when undertaking activities in locations close to ponds, lakes, rivers, estuaries, weirs and other such bodies of water.\nWe provide such equipment as is necessary for such activities, including safety ropes and harnesses, etc.`, help: `One per line.` },
            ]
        },
        { name: 'policy_working_on_services', label: 'Working on Services', value: !!company?.policy_working_on_services, },
        {
            name: 'policy_working_on_site', label: 'Working on Site', value: !!company?.policy_working_on_site,
            extras: [
                { name: 'policy_working_on_site_visit_a_premises', label: `Visit a premises`, value: company?.policy_working_on_site_visit_a_premises as string, type: 'text', placeholder: 'may/will' },
                { name: 'policy_working_on_site_in_accordance', label: `In Accordance`, value: company?.policy_working_on_site_in_accordance as string, type: 'text', placeholder: 'in accordance with the guidance document L153, prior to work commencing' },
                { name: 'policy_working_on_site_manager', label: `Manager`, value: company?.policy_working_on_site_manager as string, type: 'text', placeholder: 'a manager' },
                { name: 'policy_working_on_site_visit_a_construction_site', label: `Visit a construction site`, value: company?.policy_working_on_site_visit_a_construction_site as string, type: 'text', placeholder: 'may/will' },
            ]
        },
        { name: 'policy_working_outdoors', label: 'Working Outdoors', value: !!company?.policy_working_outdoors, },
        { name: 'policy_worksafe_policy', label: 'Worksafe Policy', value: !!company?.policy_worksafe_policy, },
    ]);


    return (
        <div className="rounded-md bg-white shadow px-3 py-3 mx-3">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="border-gray-200 flex justify-between border-b mb-3 pb-2">
                    <h4 className={``}>Company Details</h4>
                    <div className="flex gap-2">
                        <Button
                            type="submit"
                            color="green"
                        >Save</Button>
                        <Button
                            onClick={() => { handleDownloadPolicy(company?.id as string) }}
                            type="button"
                            color="orange"
                        >Download</Button>
                        <Button
                            to={'../../'}
                            type="button"
                            color="blue"
                        >Back</Button>
                    </div>
                </div>

                <div className="space-y-4">
                    <div>
                        <Label required>Company Name</Label>
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={company?.name}
                            required={true}
                            invalid={!!errors.name}
                            {...register('name', { required: true })}
                        />
                    </div>

                    <div>
                        <Label>Introduction Description</Label>
                        <TextArea
                            disabled={loading}
                            defaultValue={company?.policy_description}
                            {...register('policy_description')}
                            placeholder="Description of company, their scope of work, ethos, values, and mission statements if applicable. "
                        />
                    </div>

                    <div>
                        <Label required>Policy Location </Label>
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={company?.policy_location}
                            required={true}
                            invalid={!!errors.policy_location}
                            {...register('policy_location', { required: true })}
                            // placeholder="Location"
                        />
                    </div>

                    <div>
                        <Label>Managing Director </Label>
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={company?.policy_md}
                            {...register('policy_md')}
                            // placeholder="Managing Director"
                        />
                    </div>

                    <div>
                        <Label>Director & Company Secretary</Label>
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={company?.policy_director_secretary}
                            {...register('policy_director_secretary')}
                        />
                    </div>

                    <div>
                        <Label>Contract Managers</Label>
                        <TextArea
                            disabled={loading}
                            defaultValue={company?.policy_managers}
                            {...register('policy_managers')}
                        />
                    </div>

                    <div>
                        <Label>Responsibilities - Information for Employees</Label>
                        <TextArea
                            disabled={loading}
                            defaultValue={company?.policy_responsibilities_information}
                            {...register('policy_responsibilities_information')}
                            placeholder="We comply with this requirement by displaying the approved Health and Safety Information poster on our premises. This poster is kept in a readable condition. For remote employees, we ensure the approved Health and Safety Information leaflet is provided."
                        />
                    </div>



                    <div className="flex">
                        <h4 className={`flex-1`}>Optional Sections</h4>
                        <div><CustomSelect
                            onChange={(e) => {
                                const key = e.target.value;
                                const updatedOptions = [...options];
                                if (!!key) {
                                    for (const option of options) {
                                        setValue(option.name, false);
                                        const i = updatedOptions.findIndex(x => x.name === option.name);
                                        updatedOptions[i].value = false;
                                        switch (option.name) {
                                            case 'policy_behavioural_safety':
                                            case 'policy_contractor':
                                            case 'policy_coshh':
                                            case 'policy_display_screen_equipment':
                                            case 'policy_drugs_and_alcohol':
                                            case 'policy_emergency_procedures':
                                            case 'policy_english':
                                            case 'policy_liaison_with_sa':
                                            case 'policy_manual_handling':
                                            case 'policy_mental_health':
                                            case 'policy_monitoring':
                                            case 'policy_portable_appliance_testing':
                                            case 'policy_public_health':
                                            case 'policy_recording_accidents':
                                            case 'policy_riddor':
                                            case 'policy_risk_assessments':
                                            case 'policy_training_supervision':
                                            case 'policy_vulnerable_persons':
                                            case 'policy_waste_management':
                                            case 'policy_work_equipment':
                                                setValue(option.name, true);
                                                updatedOptions[i].value = true;
                                        }
                                    }
                                    setOptions(updatedOptions);
                                }
                            }}
                        >
                            <option value="">Select Default</option>
                            <option value="selection_1">General Set</option>
                        </CustomSelect></div>
                    </div>
                    <div className="grid grid-cols-3 mb-6 space-y-2 gap-2">
                        {options.map((option) => {
                            return (
                                <div key={option.name} className={
                                    classNames(
                                        "space-y-2",
                                        { "col-span-3": !!option.extras && option.extras.length > 0 && !!watchAllFields[option.name] }
                                    )}>
                                    <div className="flex">
                                        <CheckboxControl
                                            label={option.label}
                                            name={option.name}
                                            control={control}
                                            disabled={loading}
                                            defaultChecked={!!option.value}
                                        />
                                    </div>
                                    {!!option.extras && option.extras.length > 0 && !!watchAllFields[option.name] && <div className="border rounded-lg p-2 space-y-2">

                                        {option.extras?.map((x) => {
                                            if (x.type === 'checkbox') {
                                                return (
                                                    <>
                                                        <Label>{x.label}</Label>
                                                        <CheckboxControl
                                                            name={x.name}
                                                            control={control}
                                                            disabled={loading}
                                                            defaultChecked={!!x.value}
                                                        />
                                                        {x.help && <small>{x.help}</small>}
                                                    </>
                                                )
                                            }

                                            if (x.type === 'text') {
                                                return (
                                                    <div className="">
                                                        <Label className="flex-1">{x.label}</Label>
                                                        <Input
                                                            placeholder={x.placeholder}
                                                            disabled={loading}
                                                            type="text"
                                                            defaultValue={x.value as string}
                                                            {...register(x.name)}
                                                        />
                                                        {x.help && <small>{x.help}</small>}
                                                    </div>
                                                )
                                            }

                                            return (
                                                <div className="">
                                                    <Label>{x.label}</Label>
                                                    <TextArea
                                                        placeholder={x.placeholder}
                                                        disabled={loading}
                                                        defaultValue={x.value as string}
                                                        // placeholder="eg: small animals, livestock & equines"
                                                        {...register(x.name)}
                                                    />
                                                    {x.help && <small>{x.help}</small>}
                                                </div>
                                            )
                                        })}
                                    </div>}

                                </div>)
                        })}

                    </div>
                </div>
            </form >
        </div >
    )
}

export default CompanyPolicyForm;