import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from 'react-hot-toast';

import { urls } from "../../common";
import { useAdmin } from "../../context/Administrator";
import { FullUser } from "../../types/user";
import LoadingDots from "../../components/LoadingDots";
import Button from "../../components/Button";
import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import CheckboxControl from "../../components/form/Checkbox";

type formProps = FullUser & { confirmpassword?: string }

const AdminForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const { admin, setAdmin } = useAdmin();
    const [preview, setPreview] = useState<string>();
    const [image, setImage] = useState<any>();

    const strongRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');

    const { register, handleSubmit, watch, formState: { errors }, setValue, control } = useForm<formProps>({ reValidateMode: 'onChange' });
    const watchPassword = watch();

    useEffect(() => {
        if (admin?.profile) { setPreview(`${urls.remoteURL}${admin.profile}`); }
    }, [admin])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setPasswordChanged(!!value.password);
        });
        return () => subscription.unsubscribe();
    }, [watchPassword])

    const onSubmit = (data: formProps) => {
        // setLoading(true);
        // console.log(data);
        const formData = new FormData();
        formData.append("firstname", data?.firstname as string);
        formData.append("lastname", data?.lastname as string);
        formData.append("email", data?.email as string);
        if (data.password) formData.append("password", data.password);

        if (image) {
            formData.append("profile", image[0]);
            data.profile = image[0];
        }

        // console.log(data);


        axios({ method: 'put', url: `${urls.remoteURL}admin/${admin?.id}`, data: formData, headers: { "Content-Type": "multipart/form-data" } })
            .then((x) => {
                // console.log(x.data);
                setLoading(false);
                // setAdminUser((prev) => ({ ...prev }));
                toast.success('Administrator saved')
            })
            .catch((err) => {

                setLoading(false);
                setError(true);
                // setErrorMsg('Please check you have filled everything in correctly.')
                toast.error(err.response.data.error || 'Error Saving, please check you have filled everything in correctly.')
            })
            .finally(() => {
                setLoading(false);
                setValue('password', undefined);
                setValue('confirmpassword', undefined);
            });
    }

    const updatePreview = (e: any) => {
        setPreview(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files);
    }



    return (
        <>
            {loading && <LoadingDots />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="border-gray-200 flex justify-between border-b mb-3 pb-2">
                    <h4 className={``}>Administrator Details</h4>
                    <Button type="submit" color="green" >Save</Button>
                </div>

                <div className="mb-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <Label>Firstname</Label>
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={admin?.firstname}
                            required={true}
                            invalid={!!errors.firstname}
                            {...register('firstname', { required: true })}
                        />
                    </div>
                    <div className="sm:col-span-3">
                        <Label>Lastname</Label>
                        <Input
                            type="text"
                            disabled={loading}
                            defaultValue={admin?.lastname}
                            required={true}
                            invalid={!!errors.lastname}
                            {...register('lastname', { required: true })}
                        />
                    </div>
                </div>

                <div className=" mb-3">
                    <Label>Email</Label>
                    <Input
                        type="email"
                        disabled={loading}
                        defaultValue={admin?.email}
                        required={true}
                        invalid={!errors.email}
                        {...register('email', { required: true })}
                    />
                </div>

                <h5 className={`text-2xl border-b mb-3`}>Profile</h5>
                <div className="flex items-center mb-3">
                    <div className="shrink-0">
                        <img className="h-16 w-16 object-cover rounded-full" src={!preview ? "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80" : preview} alt="Current profile photo" />
                    </div>
                    <label className="block ml-2">
                        <span className="sr-only">Choose profile photo</span>
                        <input type="file" accept="image/*" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-rhinoBlue-100 file:text-rhinoBlue-700 hover:file:bg-rhinoBlue-200"
                            onChange={updatePreview} />
                    </label>
                </div>
                <h5 className={`text-2xl border-b mb-3`}>Security</h5>
                <div className="mb-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <Label>Password</Label>
                        <Input
                            type="password"
                            disabled={loading}
                            required={passwordChanged}
                            invalid={!!errors.password}
                            {...register('password', {
                                required: passwordChanged,
                                validate: (value) => {
                                    return !passwordChanged ? true : strongRegex.test(value as string);
                                }
                            })}
                        />
                        {!!errors.password && <p className="text-red-700">Please use strong password, min 8 characters</p>}
                    </div>
                    <div className="sm:col-span-3">
                        <Label>Confirm Password</Label>
                        <Input
                            type="password"
                            disabled={loading}
                            required={passwordChanged}
                            invalid={!!errors.confirmpassword}
                            {...register('confirmpassword', {
                                required: passwordChanged,
                                validate: (value) => {
                                    return !passwordChanged ? true : value === watch('password');
                                }
                            })}
                        />
                        {!!errors.confirmpassword && <p className="text-red-700">PlPasswords must match</p>}
                    </div>
                </div>

                {/* {admin?.deletable &&
                    <>
                        <h5 className={`text-2xl border-b mb-3`}>Access</h5>

                        <div className="mb-3">
                            <CheckboxControl
                                control={control}
                                label="Active"
                                disabled={loading}
                                defaultChecked={!!admin?.active}
                                {...register('active')}
                            />
                        </div>
                    </>
                } */}

            </form>
        </>
    )
}

export default AdminForm;