import React from "react";
import { Link, useRouteError } from "react-router-dom";

type props = {
    fullscreen?: boolean
    parent?: string
    hideButton?: boolean,
    title?: string
}

const Error = ({ fullscreen, parent, hideButton, title }: props) => {
    const error = useRouteError() as any;
    return (
        <div className={(fullscreen ?
            `flex-1 bg-white min-h-screen` : `bg-white w-full rounded-lg `) + `bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8`}>
            <div className="max-w-max mx-auto">
                <main className="sm:flex">
                    <p className="text-4xl font-extrabold text-red-700 sm:text-5xl">{error?.status || 500}</p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{title || 'Oops'}</h1>
                            <p className="mt-1 text-base text-gray-500">{error?.message || `There has been an error, please try again or contact technical support.`}.</p>
                        </div>
                        {!hideButton &&
                            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                                <Link
                                    to={`${parent}`}
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Go back
                                </Link>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}

Error.defaultProps = {
    fullscreen: true,
    hideButton: true,
    parent: '',
    title: 'Oops'
}

export default Error;