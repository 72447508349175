import { useState, createContext, useContext, useEffect } from 'react'
import { tokenID, urls } from "../common";
import axios, { AxiosError } from 'axios';
import LoadingDots from '../components/LoadingDots';
import { FullCompany } from '../types/company';


interface companyContext {
  company: FullCompany | null;
  setCompany: React.Dispatch<React.SetStateAction<FullCompany | null>>;
}

const CompanyContext = createContext<companyContext>({} as companyContext);

export function CompanyProvider({ id, children }: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<FullCompany | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  const context = { company, setCompany };


  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}company/${id}`)
        .then(({ data }) => {
          setCompany(data);
          setLoading(false)
        })
        .catch((e) => {
          setCompany({});
          setLoading(false)
        });
    } else {
      setLoading(false);
      setCompany({});
    }
  }, [id, refresh]);

  return (
    <CompanyContext.Provider value={context}>
      {loading ? <LoadingDots /> : children}
    </CompanyContext.Provider>
  );
}

export function useCompany() {
  return useContext(CompanyContext);
}