import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from 'react-hot-toast';


import { useCompany } from "../../context/Company";
import { FullQuote } from "../../types/quote";
import { urls } from "../../common";
// import QuoteForm from "../Quotes/Form";
import QuoteForm from "../Quotes/ServiceForm";
import LoadingDots from "../../components/LoadingDots";
import { FullCompany } from "../../types/company";
import { useParams } from "react-router-dom";

type props = {
    quoteID: string;
};

const EditQuote = () => {
    const { quoteID } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const { company } = useCompany();
    const [quote, setQuote] = useState<FullQuote | null>()

    useEffect(() => {
        axios.get(`${urls.remoteURL}quotes/${quoteID}`)
            .then(({ data }) => {
                setQuote(data);
                setLoading(false);
            })
            .catch((e) => {
                setQuote(null);
                setLoading(false);
            });

    }, []);

    return (
        <>
            {loading ? <LoadingDots /> :
                <QuoteForm current={quote as FullQuote} company={company as FullCompany} />}
        </>
    )
}

export default EditQuote;