import React, { useEffect } from "react";
import Swal from 'sweetalert2'

import axios from "axios";

import { urls } from "../../common";
import { NavigateFunction } from "react-router-dom";



export const handleAddAdministrator = (navigate: NavigateFunction) => {
    Swal.fire({
        title: 'Create New Administrator',
        html: '<label class="text-left block font-medium text-gray-700">Firstname</label><input id="firstname" class="mb-2 w-full block text-gray-600 px-3 py-2 border-gray-400 rounded-md border">' +
            '<label class="text-left block font-medium text-gray-700">Lastname</label><input id="lastname" class="mb-2 w-full  block text-gray-600 px-3 py-2 border-gray-400 rounded-md border">' +
            '<label class="text-left block font-medium text-gray-700">Email</label><input id="email" type="email" class="mb-2 w-full block text-gray-600 px-3 py-2 border-gray-400 rounded-md border">',
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        focusConfirm: false,
        preConfirm: () => {
            const firstname = (document.getElementById('firstname') as HTMLInputElement).value;
            const lastname = (document.getElementById('lastname') as HTMLInputElement).value;
            const email = (document.getElementById('email') as HTMLInputElement).value;

            if (!firstname || !lastname || !email) {
                Swal.showValidationMessage(`Fill in all fields.`)
                return;
            }
            return axios.post(`${urls.remoteURL}admin`, { firstname, lastname, email })
                .then((x) => x.data)
                .catch((err) => {
                    Swal.showValidationMessage(`Problem Saving Administrator`)
                });

        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            console.log(result);

            Swal.fire({
                title: `New Administrator saved`
            })
                .then(() => {
                    navigate(`/administrators/${result.value.id}`)
                })
        }
    })
}


export const handleAdminDeleteClick = (id: string, refetch: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}admin/${id}`).then(() => {
                Swal.fire(
                    'Deleted!',
                    'The Administrator has been deleted.',
                    'success'
                );
                refetch();
            });

        }
    })

}
