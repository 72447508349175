import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from 'react-hot-toast';
import uuid from 'react-uuid'

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


import { urls } from "../../common";
import { useService } from "../../context/Service";
import { FullService } from "../../types/service";
import LoadingDots from "../../components/LoadingDots";
import Button from "../../components/Button";
import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown, faCircleCheck, faXmark } from "@fortawesome/pro-solid-svg-icons";
import CheckboxControl from "../../components/form/Checkbox";

const ServiceForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { service, setService } = useService();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event: { active: any; over: any; }) {
        const { active, over } = event;
        if (active.id === over.id) { return; }
        const updated = service?.options ? [...service?.options] : [];
        const oldIndex = updated.findIndex(x => x.id === active.id);
        const newIndex = updated.findIndex(x => x.id === over.id);

        setService(prev => ({ ...prev, options: arrayMove(updated, oldIndex, newIndex) }));
    }

    const [newOption, setNewOption] = useState<string>('');
    const [newTravelOption, setNewTravelOption] = useState<string>('');
    const [newTravelRate, setNewTravelRate] = useState<number>(0);
    const [newPackageOption, setNewPackageOption] = useState<string>('');
    const [newPackageRate, setNewPackageRate] = useState<number>(0);
    const [newPaymentOption, setNewPaymentOption] = useState<string>('');
    const [newPaymentRate, setNewPaymentRate] = useState<number>(1);
    const [newPaymentMonths, setNewPaymentMonths] = useState<number>(12);

    const { register, handleSubmit, formState: { errors }, control } = useForm<FullService>();
    const onSubmit = (data: FullService) => {
        if (!service?.options || service?.options.length === 0) {
            toast.error('Error Saving, please add options.');
            return false;
        }
        // setLoading(true);
        data = {
            ...data,
            options: service.options,
            travel_options: service.travel_options,
            package_options: { ...data.package_options, options: service.package_options?.options },
            payment_options: { ...data.payment_options, options: service.payment_options?.options }
        };
        axios.put(`${urls.remoteURL}services/`, { ...data, id: service.id })
            .then((x) => {
                // console.log(x.data);
                setLoading(false);
                setService((prev) => ({ ...prev, ...data }));
                toast.success('Service saved')
            })
            .catch((err) => {
                setLoading(false);
                toast.error('Error Saving, please check you have filled everything in correctly.')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const ServiceOption = ({ option, i }: { option: any, i: number }) => {

        const {
            attributes,
            listeners,
            setNodeRef,
            transform,
            transition,
        } = useSortable({ id: option.id });


        const style = {
            transform: CSS.Translate.toString(transform),
            transition,
            height: 'auto',
        };
        return (
            <div ref={setNodeRef} style={style}
                className='mt-3 flex items-center gap-2' key={`option-${option.id}`}>
                <Input
                    type="text"
                    disabled={!!loading}
                    placeholder='Option'
                    defaultValue={option.value}
                    className={'flex-1'}
                    // {...register(`options.${i}.value`)}
                    onBlur={(x) => {
                        if (service?.options && service.options[i]) {
                            service.options[i].value = x.target.value;
                        }
                        setService({ ...service, options: service?.options });
                    }}
                />
                <div className="flex gap-2">
                    <Button type="button" {...attributes} {...listeners}><FontAwesomeIcon icon={faArrowsUpDown} fixedWidth className="h-5 w-5" /></Button>
                    <Button
                        type="button"
                        color="red"
                        onClick={() => {
                            service?.options?.splice(i, 1);
                            setService({ ...service, options: service?.options });
                        }}><FontAwesomeIcon icon={faXmark} fixedWidth className="h-5 w-5" /></Button>
                </div>
            </div>
        )
    }

    return (
        <>
            {loading && <LoadingDots />}
            <form onSubmit={e => e.preventDefault()}>
                <div className="border-gray-200 flex justify-between border-b mb-3 pb-2">
                    <h4 className={``}>Service Details</h4>
                    <Button
                        type="button"
                        color="green"
                        onClick={handleSubmit(onSubmit)}>Save</Button>
                </div>
                <div className=" mb-3">
                    <Label>Service Name</Label>
                    <Input
                        type="text"
                        disabled={loading}
                        defaultValue={service?.name}
                        required={true}
                        invalid={!!errors.name}
                        {...register('name', { required: true })}
                    />
                </div>
                <div className="border-gray-200  mb-3 mt-4 pb-2 gap-y-1">
                    <h4 className={`border-b  mb-3 `}>Options</h4>
                    {service?.options && service?.options?.length > 0 &&
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={service?.options as any}
                                strategy={verticalListSortingStrategy}
                            >
                                {service?.options?.map((option, i) => <ServiceOption key={option.id} option={option} i={i} />)
                                }
                            </SortableContext>
                        </DndContext>
                    }

                    <div className='mt-3 flex items-center gap-2' key={`new_option`}>
                        <Input
                            key={`new_option`}
                            type='text'
                            id={`new_option`}
                            className={'flex-1'}
                            placeholder='New Option'
                            value={newOption}
                            onChange={(e) => { setNewOption(e.target.value) }}
                        />
                        <div>
                            <Button
                                type="button"
                                color="green"
                                onClick={() => {
                                    const value = { value: newOption, id: uuid() };
                                    if (!!value.value) {
                                        const options = !service?.options ? [value] : [...service.options, value];
                                        setService({ ...service, options: options });
                                    }
                                    setNewOption('');
                                }}><FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5" /></Button>
                        </div>
                    </div>

                </div>




                <div className="border-gray-200  mb-3 mt-4 pb-2 gap-y-1">
                    <h4 className={`border-b  mb-3`}>Travel Options</h4>

                    {service?.travel_options?.options && service?.travel_options?.options.length > 0 &&
                        service?.travel_options?.options?.map((option, i) => {
                            return (
                                <div className='mt-3 flex items-center gap-2' key={`option-${option.id}`}>
                                    <Input
                                        type="text"
                                        disabled={loading}
                                        placeholder='Travel Option'
                                        className="flex-1"
                                        defaultValue={option.value}
                                        onChange={(x) => {
                                            if (service?.travel_options?.options && service.travel_options.options[i]) {
                                                service.travel_options.options[i].value = x.target.value;
                                            }
                                            setService({ ...service, travel_options: service.travel_options });
                                        }}
                                    />
                                    <Label>Multiplier</Label>
                                    <Input
                                        type='number'
                                        disabled={loading}
                                        step={"0.01"}
                                        min={0}
                                        className={'flex-1'}
                                        defaultValue={option.rate}
                                        onChange={(x) => {
                                            if (service.travel_options?.options && service.travel_options.options[i]) {
                                                service.travel_options.options[i].rate = parseFloat(x.target.value);
                                            }
                                            setService({ ...service, travel_options: service.travel_options });
                                        }}
                                    />
                                    <div>
                                        <Button
                                            type="button"
                                            color="red"
                                            onClick={() => {
                                                service?.travel_options?.options?.splice(i, 1);
                                                setService({ ...service, travel_options: { ...service.travel_options, options: service?.travel_options?.options } });
                                            }}><FontAwesomeIcon icon={faXmark} fixedWidth className="h-5 w-5" /></Button>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='mt-3 flex items-center gap-2' key={`new_travel_option`}>
                        <Input
                            // key={`new_travel_option`}
                            type='text'
                            // id={`new_travel_option`}
                            className={'flex-1'}
                            placeholder='New Travel Option'
                            value={newTravelOption}
                            onChange={(e) => setNewTravelOption(e.target.value)}
                        />
                        <Label>Multiplier</Label>
                        <Input
                            type='number'
                            step={"0.01"}
                            min={0}
                            className={'flex-1'}
                            placeholder='0.05'
                            value={newTravelRate}
                            onChange={(e) => setNewTravelRate(parseFloat(e.target.value))}
                        />
                        <div>
                            <Button
                                type="button"
                                color="green"
                                onClick={() => {
                                    const value = { value: newTravelOption, rate: newTravelRate as number, id: uuid() };
                                    if (!!value.value) {
                                        const options = !service?.travel_options?.options ? [value] : [...service?.travel_options.options, value];
                                        setService({ ...service, travel_options: { ...service?.travel_options, options } });
                                        setNewTravelOption('');
                                        setNewTravelRate(0);
                                    }
                                }}><FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5" /></Button>
                        </div>
                    </div>
                </div>


                <div className="border-gray-200 mb-3 mt-4 pb-2">
                    <h4 className={`border-b  mb-3`}>Site Options</h4>
                    <div className=" mb-3">
                        <Label>Max sites</Label>
                        <Input
                            type="number"
                            min={0}
                            disabled={loading}
                            defaultValue={service?.site_options?.max || 1}
                            required={true}
                            invalid={!!errors.site_options}
                            {...register('site_options.max', { required: true })}
                        />
                    </div>
                    <div className=" mb-3">
                        <Label>Multiplier</Label>
                        <Input
                            type="number"
                            min={0}
                            step={"0.01"}
                            disabled={loading}
                            defaultValue={service?.site_options?.rate || "0.05"}
                            required={true}
                            invalid={!!errors.site_options}
                            {...register('site_options.rate', { required: true })}
                        />
                    </div>
                </div>


                <div className="border-gray-200 mb-3 mt-4 pb-2">
                    <h4 className={`border-b  mb-3`}>Package Options</h4>

                    {service?.package_options?.options && service?.package_options?.options.length > 0 &&
                        service?.package_options?.options?.map((option: any, i: number) => {
                            return (
                                <div className='mt-3 flex items-center gap-2' key={`option-${option.id}`}>
                                    <Input
                                        type="text"
                                        disabled={loading}
                                        placeholder='Package Option'
                                        className="flex-1"
                                        defaultValue={option.value}
                                        onChange={(x) => {
                                            service.package_options.options[i].value = x.target.value;
                                            setService({ ...service, package_options: service.package_options });
                                        }}
                                    />
                                    <Input
                                        type='number'
                                        disabled={loading}
                                        min={0}
                                        className={'flex-1'}
                                        defaultValue={option.rate}
                                        onChange={(x) => {
                                            service.package_options.options[i].rate = x.target.value;
                                            setService({ ...service, package_options: service.package_options });
                                        }}
                                    />
                                    {/* <Input
                                        type='number'
                                        disabled={loading}
                                        min={0}
                                        className={'flex-1'}
                                        defaultValue={option.plus}
                                        onChange={(x) => {
                                            service.package_options.options[i].plus = x.target.value;
                                            setService({ ...service, package_options: service.package_options });
                                        }}
                                    /> */}
                                    <div className="">
                                        <Button
                                            type="button"
                                            color="red"
                                            onClick={() => {
                                                service.package_options.options.splice(i, 1);
                                                setService({ ...service, package_options: { ...service.package_options, options: service.package_options.options } });
                                            }}><FontAwesomeIcon icon={faXmark} fixedWidth className="h-5 w-5" /></Button>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='mt-3 flex items-center gap-2 mb-3' key={`new_package_option`}>
                        <Input
                            type='text'
                            className={'flex-1'}
                            placeholder='New Package Option'
                            value={newPackageOption}
                            onChange={(e) => { setNewPackageOption(e.target.value) }}
                        />
                        <Input
                            type='number'
                            step={"0.01"}
                            min={0}
                            className={'flex-1'}
                            placeholder='Rate £'
                            value={newPackageRate}
                            onChange={(e) => { setNewPackageRate(parseFloat(e.target.value)) }}
                        />
                        {/* <Input
                            type='number'
                            step={"0.01"}
                            min={0}
                            className={'flex-1'}
                            placeholder='Plus Rate £'
                            ref={newPackagePlusRef}
                        /> */}
                        <div>
                            <Button
                                type="button" color="green"
                                onClick={() => {
                                    const value = {
                                        id: uuid(),
                                        value: newPackageOption,
                                        rate: newPackageRate,
                                        // plus: newPackagePlusRef.current.value,
                                    };
                                    if (!!value.value) {
                                        const options = !service?.package_options?.options ? [value] : [...service.package_options.options, value];
                                        setService({ ...service, package_options: { ...service?.package_options, options: options } });
                                        setNewPackageOption('');
                                        setNewPackageRate(0);
                                        // newPackagePlusRef.current.value = '';
                                    }
                                }}><FontAwesomeIcon icon={faCircleCheck} fixedWidth className="h-5 w-5" /></Button>
                        </div>
                    </div>



                    {/* <div className=" mb-3">
                        <Checkbox
                            label="Enable Plus Rate"
                            disabled={loading}
                            defaultChecked={!!service.package_options?.plusrate}
                            error={errors.package_options?.plusrate}
                            value={1}
                            {...register('package_options.plusrate')}
                        />
                    </div> */}
                </div>





                <div className="border-gray-200  mb-3 mt-4 pb-2">
                    <h4 className={`border-b  mb-3`}>Payment Options</h4>

                    {service?.payment_options?.options && service?.payment_options?.options.length > 0 &&
                        service?.payment_options?.options?.map((option, i) => {
                            return (
                                <div className='mt-3 flex items-center gap-2' key={`option-${option.id}`}>
                                    <Input
                                        type="text"
                                        disabled={loading}
                                        placeholder='Payment Option'
                                        className="flex-1"
                                        defaultValue={option.value}
                                        onChange={(x) => {
                                            if (service.payment_options?.options && service.payment_options?.options[i]) {
                                                service.payment_options.options[i].value = x.target.value;
                                            }
                                            setService({ ...service, payment_options: service.payment_options });
                                        }}
                                    />
                                    <Input
                                        type='number'
                                        disabled={loading}
                                        min={0}
                                        step={"0.01"}
                                        className={'flex-1'}
                                        defaultValue={option.rate}
                                        onChange={(x) => {
                                            if (service.payment_options?.options && service.payment_options?.options[i]) {
                                                service.payment_options.options[i].rate = parseFloat(x.target.value);
                                            }
                                            setService({ ...service, payment_options: service.payment_options });
                                        }}
                                    />
                                    <Input
                                        type='number'
                                        disabled={loading}
                                        min={12}
                                        step={"1"}
                                        className={'flex-1'}
                                        defaultValue={option.months}
                                        onChange={(x) => {
                                            if (service.payment_options?.options && service.payment_options?.options[i]) {
                                                service.payment_options.options[i].months = parseFloat(x.target.value);
                                            }
                                            setService({ ...service, payment_options: service.payment_options });
                                        }}
                                    />
                                    <div>
                                        <Button
                                            type="button" color="red"
                                            onClick={() => {
                                                if (service?.payment_options?.options) {
                                                    service?.payment_options?.options.splice(i, 1);
                                                }
                                                setService({ ...service, payment_options: { ...service.payment_options, options: service?.payment_options?.options } });
                                            }}><FontAwesomeIcon icon={faXmark} fixedWidth className="h-5 w-5" /></Button>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='mt-3 flex items-center gap-2' key={`new_payment_option`}>
                        <Input
                            type='text'
                            className={'flex-1'}
                            placeholder='New Payment Option'
                            value={newPaymentOption}
                            onChange={(e) => setNewPaymentOption(e.target.value)}
                        />
                        <Input
                            type='number'
                            step={"0.01"}
                            min={0}
                            className={'flex-1'}
                            placeholder='Discount %'
                            value={newPaymentRate}
                            onChange={(e) => setNewPaymentRate(parseFloat(e.target.value))}
                        />
                        <Input
                            type='number'
                            step={"1"}
                            min={12}
                            className={'flex-1'}
                            placeholder='Months'
                            value={newPaymentMonths}
                            onChange={(e) => setNewPaymentMonths(parseInt(e.target.value))}
                        />
                        <div>
                            <Button
                                type="button" color="green"
                                onClick={() => {
                                    const value = { value: newPaymentOption, rate: newPaymentRate, months: newPaymentMonths, id: uuid() };
                                    if (!!value.value) {
                                        const options = !service?.payment_options?.options ? [value] : [...service.payment_options.options, value];
                                        setService({ ...service, payment_options: { ...service?.payment_options, options: options } });
                                        setNewPaymentOption('');
                                        setNewPaymentRate(0);
                                        setNewPaymentMonths(0)
                                    }
                                }}><FontAwesomeIcon icon={faCircleCheck} fixedWidth className="h-5 w-5" /></Button>
                        </div>
                    </div>


                    <div className=" mt-3">
                        <CheckboxControl
                            name='payment_options.onetime'
                            control={control}
                            label="One Time Payment"
                            disabled={loading}
                            defaultChecked={!!service?.payment_options?.onetime}
                            invalid={!!errors.payment_options?.onetime}
                        // value={1}
                        // {...register('payment_options.onetime')}
                        />
                    </div>
                </div>





                <div className="border-gray-200  mb-3 mt-4 pb-2">
                    <h4 className={`border-b  mb-3`}>Additional Options</h4>


                    <div className="mb-3">
                        <Label>Max Additional Days</Label>
                        <Input
                            type="number"
                            min={0}
                            disabled={loading}
                            defaultValue={service?.additional_options?.days || 1}
                            invalid={!!errors.additional_options?.days}
                            {...register('additional_options.days')}
                        />
                    </div>
                    <div className=" mb-3">
                        <Label>Day Rate (£)</Label>
                        <Input
                            type="number"
                            min={0}
                            disabled={loading}
                            defaultValue={service?.additional_options?.daysrate || "0"}
                            invalid={!!errors.additional_options?.daysrate}
                            {...register('additional_options.daysrate')}
                        />
                    </div>

                    {/* <div className=" mb-3">
                        <Input
                            label="Online courses Rate (£)"
                            type="number"
                            min={0}
                            disabled={loading}
                            defaultValue={service.additional_options?.onlinerate || "0"}
                            error={errors.site_options?.onlinerate}
                            {...register('additional_options.onlinerate')}
                        />
                    </div>

                    <div className=" mb-3">
                        <Input
                            label="Prepaid training courses Rate (£)"
                            type="number"
                            min={0}
                            disabled={loading}
                            defaultValue={service.additional_options?.trainingrate || "0"}
                            error={errors.site_options?.trainingrate}
                            {...register('additional_options.trainingrate')}
                        />
                    </div> */}

                </div>

            </form >
        </>
    )
}

export default ServiceForm;